import { action, makeAutoObservable } from 'mobx'
import { ProductsListStore } from './ProductsList.store'
import { SlidingPanelStore } from './SlidingPanel.store'
import { ThemeStore } from './Theme.store'
import { TranslationsStore } from './Translations.store'
import { TableStore } from './Table.store'
import { RouterStore } from './Router.store'
import { HeaderStore } from './Header.store'
import { UserStore } from './User.store'
import { FiltersStore } from './Filters.store'
import { CalendarStore } from './Calendar.store'
import { TagsStore } from './Tags.store'
import { MockStore } from './Mock.store'
import { PackagesStore } from './Packages.store'
import { StatusesStore } from './Statuses.store'
import { StoreSettingsStore } from './StoreSettings.store'
import { CategoriesStore } from './Categories.store'
import { AuditStore } from './Audit.store'
import { ViewStore } from './View.store'
import { ReturnsStore } from './Returns.store'
import { StorybookStore } from './Storybook.store'

export class RootStore {
  Root: RootStore
  AuditStore: AuditStore
  TableStore: TableStore
  RouteStore: RouterStore
  ProductsList: ProductsListStore
  TranslationsState: TranslationsStore
  SlidingPanelState: SlidingPanelStore
  HeaderStore: HeaderStore
  Theme: ThemeStore
  UserStore: UserStore
  FiltersStore: FiltersStore
  ViewStore: ViewStore
  CalendarStore: CalendarStore
  TagsStore: TagsStore
  StatusesStore: StatusesStore
  MockStore: MockStore
  PackagesStore: PackagesStore
  StoreSettingsStore: StoreSettingsStore
  CategoriesStore: CategoriesStore
  ReturnsStore: ReturnsStore
  Storybook: StorybookStore
  error = null

  constructor() {
    makeAutoObservable(this)
    this.AuditStore = new AuditStore(this)
    this.TranslationsState = new TranslationsStore(this)
    this.Root = this
    this.RouteStore = new RouterStore(this)
    this.TableStore = new TableStore(this)
    this.ProductsList = new ProductsListStore(this)
    this.SlidingPanelState = new SlidingPanelStore(this)
    this.Theme = new ThemeStore(this)
    this.HeaderStore = new HeaderStore(this)
    this.UserStore = new UserStore(this)
    this.FiltersStore = new FiltersStore(this)
    this.ViewStore = new ViewStore(this)
    this.CalendarStore = new CalendarStore(this)
    this.TagsStore = new TagsStore(this)
    this.StatusesStore = new StatusesStore(this)
    this.MockStore = new MockStore(this)
    this.PackagesStore = new PackagesStore(this)
    this.StoreSettingsStore = new StoreSettingsStore(this)
    this.CategoriesStore = new CategoriesStore(this)
    this.ReturnsStore = new ReturnsStore(this)
    this.Storybook = new StorybookStore(this)
  }

  @action.bound setFilterInTable = (
    filterName: string,
    filterValue: string | number,
  ) => {
    // this[this.currentTable as keyof typeof this].setFilters(
    //   filterName,
    //   filterValue
    // )
    this['ProductsList'].setFilters(filterName, filterValue)
  }
}

export const rootStore = new RootStore()
