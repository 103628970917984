import { Row } from '@tanstack/react-table'
import React from 'react'

import { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'

import {
  DefaultColumnInput,
  GlobalFilterInput,
  SliderInput,
  StyledSelectColumn,
  StyledSelectOption,
  SliderButton,
  SliderCont,
  StyledForm,
  StyledClearCont,
} from './Filters.styles'
import { mainTheme as theme } from '../../static/themes/main.theme'
import { toast } from 'react-toastify'
import ClearFilters from '../../assets/icons/clear-filters.svg'
import SearchFieldButton from '../TableGeneric/searchFieldButton/SearchFieldButton.component'
import { Tooltip } from '@mui/material'
import { QueryRulesCont } from '../TableGeneric/table/Table.styles'

const updateFilterInStore = (
  columnName: string,
  value: string | number,
  storeUpdate: (value: string, columnName: string | number) => void
) => {
  storeUpdate(columnName, value)
}

interface GlobalFilterProps {
  fetchData: () => void
}

/* eslint-disable */
export const GlobalFilter = observer(({ fetchData }: GlobalFilterProps) => {
  const store = useContext(StoreContext)
  const { globalSearch, setGlobalSearch } = store.TableStore
  const locales = store.TranslationsState.translations
  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (globalSearch.length >= 3 || globalSearch.length == 0)
        store.TableStore.applyQuery(fetchData)
      else
        toast('Search query must be at least 3 characters long', {
          type: 'error',
        })
    }
  }

  return (
    <StyledForm>
      {locales.buttons.searchAll}:{' '}
      <GlobalFilterInput
        value={globalSearch}
        onChange={(e) => setGlobalSearch(e.target.value)}
        placeholder={locales.buttons.setGlobalSearch}
        onKeyDown={handleKeyDown}
        {...theme.filtersStyles.globalFilterInput}
      />
      <StyledClearCont>
        {store.TableStore.appliedGlobalSearch.length ? (
          <SearchFieldButton
            text={'Clear Global Filter'}
            onClick={() => {
              store.TableStore.setGlobalSearch('')
              store.TableStore.applyQuery(fetchData)
            }}
            icon={<img src={ClearFilters} />}
          />
        ) : (
          <></>
        )}
      </StyledClearCont>
    </StyledForm>
  )
})

// Define a default UI for filtering
interface DefaultColumnInterface {
  column: {
    filterValue: string | undefined
    setFilter: (value: string | undefined) => void
    id: string
  }
}

export const DefaultColumnFilter = observer(
  ({ column: { filterValue, setFilter, id } }: DefaultColumnInterface) => {
    const store = useContext(StoreContext)
    const { setFilterInTable } = store.Root
    return (
      <DefaultColumnInput
        value={filterValue || undefined}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          updateFilterInStore(id, e.target.value, setFilterInTable)
        }}
        placeholder={'Search...'}
        {...theme.filtersStyles.defaultColumnInput}
      />
    )
  }
)

// This is a custom filter UI for selecting
// a unique option from a list
interface SelectColumnInterface {
  column: {
    filterValue: string | undefined
    setFilter: (value: string | undefined) => void
    preFilteredRows: any[]
    id: string
  }
}

export const SelectColumnFilter = observer(
  ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }: SelectColumnInterface) => {
    const store = useContext(StoreContext)
    const { setFilterInTable } = store.Root
    const options = React.useMemo(() => {
      const options: Set<string> = new Set()
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <StyledSelectColumn
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
          updateFilterInStore(id, e.target.value, setFilterInTable)
        }}
        {...theme.filtersStyles.styledSelectColumn}
      >
        <StyledSelectOption value=''>All</StyledSelectOption>
        {options.map((option: string, i: number) => (
          <StyledSelectOption
            key={i}
            value={option}
            {...theme.filtersStyles.styledSelectOption}
          >
            <span>{option}</span>
          </StyledSelectOption>
        ))}
      </StyledSelectColumn>
    )
  }
)

interface SliderColumnInterface {
  column: {
    filterValue: string | undefined
    setFilter: (value: string | undefined) => void
    preFilteredRows: any[]
    id: string
  }
}

export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: SliderColumnInterface) {
  const store = useContext(StoreContext)
  const { setFilterInTable } = store.Root

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <SliderCont>
      <SliderInput
        type='range'
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
          updateFilterInStore(id, e.target.value, setFilterInTable)
        }}
      />
      <SliderButton
        onClick={() => setFilter(undefined)}
        {...theme.filtersStyles.sliderButton}
      >
        Off
      </SliderButton>
    </SliderCont>
  )
}

interface NumberRangeInterface {
  column: {
    filterValue: Array<string | number | undefined>
    setFilter: (filterValue: any) => void
    preFilteredRows: any[]
    id: string
  }
}

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: NumberRangeInterface) {
  const store = useContext(StoreContext)
  const { setFilterInTable } = store.Root
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div>
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          updateFilterInStore(id, e.target.value, setFilterInTable)
        }}
        placeholder={`Min (${min})`}
      />
      to
      <input
        value={filterValue[1] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          updateFilterInStore(id, e.target.value, setFilterInTable)
        }}
        placeholder={`Max (${max})`}
      />
    </div>
  )
}
