/* eslint-disable */
import React, {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Container, InnerCont, PrimaryButton } from './ChooseTreeGeneric.styles'

import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import CreateTagComponent from '../../tags/edits/createTag/CreateTag.component'
import AddTagTreeComponent from '../../tags/edits/addTagTree/AddTagTree.component'
import CreateTreeComponent from '../createTree/CreateTree.component'
import AddTreeComponent from '../addTree/AddTree.component'

export interface ChooseTagTreeProps extends PropsWithChildren {
  id: string
  columns: Array<{ header: string; value: string | object }>
  copyColumns: Array<{ header: string; value: string | object }>
  hideParent: boolean
  view: 'group' | 'tag' | 'root'
  left?: boolean
  parentId: string
  firstLevel: boolean
  mainDesc: string
  baseUrl: string
  searchComponent: any
  addExistingLeftFunction: (
    currentId: string,
    newId: string,
    parentId: string
  ) => void
  addExistingRightFunction: (
    currentId: string,
    newId: string,
    parentId: string
  ) => void
}

const ChooseTagTree: (props: ChooseTagTreeProps) => JSX.Element = observer(
  ({
    id,
    columns,
    hideParent,
    view,
    copyColumns,
    firstLevel,
    left,
    parentId,
    mainDesc,
    baseUrl,
    searchComponent,
    addExistingLeftFunction,
    addExistingRightFunction,
  }: ChooseTagTreeProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [selected, setSelected] = useState<null | string>(null)

    useEffect(() => {
      setSelected(null)
    }, [store.SlidingPanelState.visibility])

    const renderContent = () => {
      switch (selected) {
        case 'new':
          return (
            <CreateTreeComponent
              columns={columns}
              hideParent={hideParent}
              view={'tag'}
              left={left}
              parentId={parentId}
              id={id}
              baseUrl={baseUrl}
            />
          )
        case 'existing':
          return (
            <AddTreeComponent
              id={id}
              left={left}
              parentId={parentId}
              searchComponent={searchComponent}
              addExistingLeftFunction={addExistingLeftFunction}
              addExistingRightFunction={addExistingRightFunction}
            />
          )
        case 'copy':
          return (
            <CreateTreeComponent
              columns={copyColumns}
              hideParent={true}
              view={'tag'}
              left={left}
              parentId={parentId}
              id={id}
              baseUrl={baseUrl}
            />
          )
        default:
          return (
            <InnerCont cols={1}>
              <p> Select action: </p>
              <PrimaryButton
                {...Theme.buttons.primaryButton}
                onClick={() => setSelected('new')}
              >
                + Create New {mainDesc}
              </PrimaryButton>
              <PrimaryButton
                {...Theme.buttons.primaryButton}
                onClick={() => setSelected('existing')}
              >
                Use an existing {mainDesc}
              </PrimaryButton>
              {!firstLevel ? (
                <PrimaryButton
                  {...Theme.buttons.primaryButton}
                  onClick={() => setSelected('copy')}
                >
                  Copy an existing {mainDesc}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </InnerCont>
          )
      }
    }

    return (
      <Container {...Theme.editProductsStyles.container}>
        {renderContent()}
      </Container>
    )
  }
)

export default ChooseTagTree
