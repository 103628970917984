import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropDownItem,
  FilterLabel,
  ImageCont,
  Input,
  TextCont,
} from './AddProductSearch.styles'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios from '../../axiosconfig'

interface AddProductSearchProps {
  applySearch: (product: ProductType) => void
  clearInputValue?: boolean
}

const AddProductSearchComponent = observer(
  ({ applySearch, clearInputValue }: AddProductSearchProps) => {
    const [data, setData] = useState<ProductType[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    //const [currentPage, setCurrentPage] = useState(0)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedProducts = async (from = 0) => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=10&from=${from}`

      const requestData = {
        filterGroups: {
          rules: [
            {
              field: 'id',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
            {
              field: 'code',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
            {
              field: 'name',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
          ],
          combinator: 'or',
          not: false,
        },
      }

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      const response = await axios.post(url, requestData, config)

      if (response.status === 200) {
        setData((prevData) => [...prevData, ...response.data.products])
      }
    }

    const handleScroll = useCallback(() => {
      if (!dropdownRef.current) return

      //const dropdownElement = dropdownRef.current

      /*if (
              dropdownElement.scrollTop + dropdownElement.clientHeight >=
              dropdownElement.scrollHeight
            ) {
              setCurrentPage((prevPage) => {
                const newPage = prevPage + 1
                searchRecommendedProducts(newPage * 10) // Use the updated page value directly here
                return newPage
              })
            }*/
    }, [searchRecommendedProducts])

    useEffect(() => {
      if (inputValue.length >= 3) {
        setData([])
        //setCurrentPage(0)
        searchRecommendedProducts(0)
      }
    }, [inputValue])

    useEffect(() => {
      const dropDownElement = dropdownRef.current

      if (dropDownElement) {
        dropDownElement.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (dropDownElement) {
          dropDownElement.removeEventListener('scroll', handleScroll)
        }
      }
    }, [handleScroll])

    return (
      <Container>
        <FilterLabel>Search Product</FilterLabel>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={'Min 3 characters...'}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setTimeout(() => setIsInputFocused(false), 100)}
        />
        {isInputFocused && inputValue.length >= 3 ? (
          <DropDown ref={dropdownRef}>
            {data.map((e: ProductType, index: number) => (
              <DropDownItem
                key={`search-dropdown=${index}`}
                onClick={() => {
                  applySearch(e)
                  setInputValue(e.code)
                  if (clearInputValue) {
                    setInputValue('')
                  }
                }}
              >
                <ImageCont>
                  <img src={e.image} alt={e.name} />
                </ImageCont>
                <TextCont>
                  <p>{e.code}</p>
                  <p>{e.name}</p>
                </TextCont>
              </DropDownItem>
            ))}
          </DropDown>
        ) : null}
      </Container>
    )
  },
)

export default AddProductSearchComponent
