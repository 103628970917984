/* eslint-disable */
import { PropsWithChildren, useContext, useState } from 'react'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './AddTree.styles'

import { StoreContext } from '../../../../App'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  left?: boolean
  parentId: string
  searchComponent: any
  addExistingLeftFunction: (
    currentId: string,
    newId: string,
    parentId: string
  ) => void
  addExistingRightFunction: (
    currentId: string,
    newId: string,
    parentId: string
  ) => void
}

const AddTree: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [id, setId] = useState('')
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const applySearch = (obj: any) => {
    setId(obj.id)
    setData(obj)
  }

  const handleSave = async () => {
    await setLoading(true)
    if (props.left) {
      await props.addExistingLeftFunction(props.id, id, props.parentId)
    } else {
      await props.addExistingRightFunction(props.id, id, props.parentId)
    }
    await setLoading(false)
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        {props.searchComponent((obj: any) => applySearch(obj))}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => data && !loading && handleSave()}
          active={data && !loading}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default AddTree
