/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ReplaceEmptyStringsForZeros,
  ValidateArray,
} from '../../columnsConfig'
import { StoreContext } from '../../App'
import { DataInput } from '../dataInput/DataInput.component'

import {
  InnerCont,
  Footer,
  PrimaryButton,
  SecondaryButton,
  LoadingWrapper,
} from './EditMultipleProducts.styles'
import { Container, Wrap } from '../../styles/Loading.styles'
import { socket } from '../../socket/socket'

interface formInterface {
  keys: Array<string>
}

const EditMultipleProductsForm = observer(({ keys }: formInterface) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const divRef = useRef<any>()
  const dataChange = store.TableStore.dataInput

  const initialState = [
    ...keys.map((e) => {
      return {
        header: e,
        value: '',
        error: '',
        rules: ValidateArray[e] ? ValidateArray[e] : [],
      }
    }),
  ]
  const [form, setForm] = useState(initialState)

  const changedObject = () => {
    if (!divRef.current) return false
    const checkBoxes = divRef.current.querySelectorAll('input[type="checkbox"]')
    if (checkBoxes.length === 0) return false
    const length =
      Array.from(checkBoxes).filter((e: any) => e.checked).length > 0
    return length
  }

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    store.TableStore.setLoading(false)
    let data: any = {
      filterGroups: {
        rules: [],
        combinator: 'or',
        not: false,
      },
    }

    store.TableStore.selectedRowId.map((e: string, index: number) =>
      data.filterGroups.rules.push({
        field: 'id',
        operator: 'contains',
        valueSource: 'value',
        value: e,
      })
    )

    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/${store.TableStore.getApiRequestFromUrl()}?size=${
      store.TableStore.selectedRowId.length
    }&from=0`

    const response = await axios.post(url, data, {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    })
    const res = await response.data[`${store.TableStore.getResponseParam()}`]
    const currentPage = store.RouteStore.currentPage

    const fetchPromises = await res.map((single: any, i: number) => {
      let bodyData: any = {}

      if (currentPage === 'supplierorders') {
        bodyData = {
          ...bodyData,
          lastEditAuthor: store.UserStore.user,
          id: single.id,
        }
      }

      form.map((f: any, index) => {
        const selected =
          divRef.current.querySelector(`input[name="${f.header}"]`) &&
          divRef.current.querySelector(`input[name="${f.header}"]`).checked
        if (selected) {
          //console.log(f)
          if (ReplaceEmptyStringsForNulls.includes(f.header) && f.value === '')
            bodyData = { ...bodyData, [f.header]: null }
          if (f.header === 'status' && f.value === '')
            bodyData = { ...bodyData, [f.header]: '0' }
          else bodyData = { ...bodyData, [f.header]: f.value }
        }
      })

      const id = single.id
      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1/${store.TableStore.getUpadateParam()}/${id}`
      store.TableStore.setData(
        store.TableStore.data.map((e: any) =>
          e.id === single.id ? { ...e, ...bodyData } : e
        )
      )
      const formattedData = store.TableStore.formatRequestData(bodyData)
      return axios.post(url, formattedData, {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      })
    })

    await store.TableStore.setLoading(true)
    await Promise.all(fetchPromises)
    res.map((single: any) => {
      socket.emit('editOrder', {
        id: single.id,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
    })
    store.SlidingPanelState.setVisibility(false)
    //await setTimeout(async () => await store.TableStore.fetchData(), 3000)
  }
  const [inputColumns, setInputColumns] = useState(1)
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * keys.filter((e) => !hiddenColumns.includes(e)).length) /
          (height * 0.8)
      )
    )
  }, [height])

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
  }, [form])

  return (
    <div>
      <InnerCont cols={inputColumns} ref={divRef}>
        {keys.map((e, index) => (
          <DataInput
            vals={{ header: e, value: '' }}
            form={form}
            index={index}
            setForm={handleSetForm}
            key={`edit-multiple-${index}`}
          />
        ))}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (changedObject()) handleSave()
          }}
          active={changedObject()}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </div>
  )
})
export default EditMultipleProductsForm
