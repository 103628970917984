import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropdownHeader,
  DropDownItem,
  Input,
  InputCont,
  PathCont,
  TextCont,
} from './SearchTag.styles'
import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from '../../../../../axiosconfig'
import { StoreContext } from '../../../../../App'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { DeleteButton } from '../objectInput/ObjectInput.styles'
import GoBack from '../../../../../assets/icons/go-back.svg'
import ArrowRight from '../../../../../assets/icons/arrow-right.svg'
import {
  GroupTagType,
  RootTagType,
  TagType,
} from '../../../../../types/TagTypes'

interface AddProductSearchProps {
  applySearch: (val: any) => void
  header: string
  clearInputValue?: boolean
  view: 'group' | 'root' | 'tag'
}

function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

const SearchTagComponent = observer(
  ({ applySearch, clearInputValue, view, header }: AddProductSearchProps) => {
    const store = useContext(StoreContext)
    const [data, setData] = useState<
      RootTagType[] | GroupTagType[] | TagType[]
    >([])
    const [displayedData, setDisplayedData] = useState<any[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [secondTierTags, setSecondTierTags] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(0)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedProducts = async () => {
      const searchView = view === 'group' ? 'group' : 'root'
      const url =
        store.TagsStore.getUrlBasedOnView('data', undefined, searchView) +
        `?from=0&size=100`
      /*const requestData = {
                    filterGroups: {
                      rules: [
                        {
                          field: 'id',
                          operator: 'contains',
                          valueSource: 'value',
                          value: inputValue,
                        },
                      ],
                      combinator: 'or',
                      not: false,
                    },
                  }*/

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      const response = await axios.post(url, { skipChildren: false }, config)

      if (response.status === 200) {
        await setData((prevData) => [...prevData, ...response.data.tags])
      }
    }

    const setSecondTier = async (rootTag: any) => {
      setSecondTierTags(true)
      const collectTags = (tags: any[], collectedTags: any[] = []) => {
        tags.forEach((tag) => {
          collectedTags.push(tag)
        })

        return collectedTags
      }

      const allTags = collectTags(
        // eslint-disable-next-line no-prototype-builtins
        rootTag.hasOwnProperty('tags') ? rootTag.tags : rootTag.children,
      )
      setData(allTags)
    }

    /*const handleScroll = useCallback(() => {
                                          if (!dropdownRef.current) return
                            
                                          const dropdownElement = dropdownRef.current
                            
                                          if (
                                            dropdownElement.scrollTop + dropdownElement.clientHeight >=
                                            dropdownElement.scrollHeight
                                          ) {
                                            setCurrentPage((prevPage) => {
                                              const newPage = prevPage + 1
                                              searchRecommendedProducts(newPage * 10) // Use the updated page value directly here
                                              return newPage
                                            })
                                          }
                                        }, [searchRecommendedProducts])*/

    useOutsideClick(dropdownRef, () => {
      if (!isInputFocused) return
      setSecondTierTags(false)
      setData([])
      searchRecommendedProducts()
      setIsInputFocused(false)
    })

    useEffect(() => {
      if (!isInputFocused) return
      setData([])
      setCurrentPage(0)
      searchRecommendedProducts()
    }, [isInputFocused, inputValue])

    useEffect(() => {
      setDisplayedData(() =>
        data.filter(
          (e: any) =>
            Object.keys(e.name).length &&
            e.name.EN.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      )
    }, [data])

    /*useEffect(() => {
                                          const dropDownElement = dropdownRef.current
                            
                                          if (dropDownElement) {
                                            dropDownElement.addEventListener('scroll', handleScroll)
                                          }
                            
                                          return () => {
                                            if (dropDownElement) {
                                              dropDownElement.removeEventListener('scroll', handleScroll)
                                            }
                                          }
                                        }, [handleScroll])*/

    return (
      <Container ref={dropdownRef}>
        <InputCont>
          <Input
            value={inputValue}
            id={`input-${store.RouteStore.currentPage}-${header}`}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Search for ${view}...`}
            onFocus={() => setIsInputFocused(true)}
          />
          <DeleteButton
            onClick={() => {
              setSecondTierTags(false)
              setData([])
              searchRecommendedProducts()
              setIsInputFocused(false)
            }}
          >
            <img src={ClearFilters} />
          </DeleteButton>
        </InputCont>
        {isInputFocused ? (
          view !== 'tag' || secondTierTags ? (
            <DropDown>
              {view === 'tag' ? (
                <>
                  <DropdownHeader>
                    <div></div>
                    <div>Tags view</div>
                    <div
                      onClick={() => {
                        setSecondTierTags(false)
                        setData([])
                        searchRecommendedProducts()
                      }}
                    >
                      <img src={GoBack} />
                    </div>
                  </DropdownHeader>
                  {displayedData.map((e: any, index: number) => (
                    <DropDownItem
                      key={`search-dropdown=${index}`}
                      onClick={() => {
                        applySearch(e)
                        setInputValue(e.id)
                        if (clearInputValue) {
                          setInputValue('')
                        }
                      }}
                    >
                      {/*<ImageCont>
                      <img src={e.image} alt={e.name}/>
                  </ImageCont>*/}
                      <TextCont>
                        <PathCont>{e.path ? e.path.EN : ''}</PathCont>
                        <p>{e.name.EN}</p>
                      </TextCont>
                      {e.children && e.children.length ? (
                        <div
                          onClick={(event) => {
                            event.stopPropagation()
                            setSecondTier(e)
                          }}
                        >
                          <img src={ArrowRight} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </DropDownItem>
                  ))}
                </>
              ) : (
                <>
                  {displayedData.map((e: any, index: number) => (
                    <DropDownItem
                      key={`search-dropdown=${index}`}
                      onClick={() => {
                        applySearch(e)
                        setInputValue(e.id)
                        if (clearInputValue) {
                          setInputValue('')
                        }
                      }}
                    >
                      {/*<ImageCont>
                      <img src={e.image} alt={e.name}/>
                  </ImageCont>*/}
                      <TextCont>
                        <p>{e.name.EN}</p>
                      </TextCont>
                    </DropDownItem>
                  ))}{' '}
                </>
              )}
            </DropDown>
          ) : (
            <DropDown>
              {view === 'tag' ? (
                <DropdownHeader>
                  <div></div>
                  <div>Root view</div>
                  <div>
                    <img src={GoBack} />
                  </div>
                </DropdownHeader>
              ) : (
                <> </>
              )}
              {displayedData.map((e: any, index: number) => (
                <DropDownItem onClick={() => setSecondTier(e)} key={index}>
                  <b>{e.name.EN}</b>
                </DropDownItem>
              ))}
            </DropDown>
          )
        ) : null}
      </Container>
    )
  },
)

export default SearchTagComponent
