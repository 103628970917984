import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { StoreContext } from '../../../App'
import { DropDownItem, HeaderDropDown } from './TasksDropDown.styles'
import axios from '../../../axiosconfig'
import { useParams } from 'react-router-dom'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'

interface Props extends PropsWithChildren {
  table: Array<{ value: string; label: string }>
  currentStep: { value: string; label: string }
  nextStep: { value: string; label: string }
}

function removeUserNameField(obj: any) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // If the value is an object, recurse
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        removeUserNameField(obj[key])
      }
      // If the key is userName, delete it
      if (key === 'userName') {
        // Check if 'username' does not exist, then add it with the value from 'userName'
        if (!Object.prototype.hasOwnProperty.call(obj, 'username')) {
          obj['username'] = obj[key] // Set 'username' to the value of 'userName'
        }
        // Delete 'userName' key
        delete obj[key]
      }
    }
  }
}

const TasksDropDown: FC<Props> = observer(
  ({ table, currentStep, nextStep }: Props) => {
    const store = useContext(StoreContext)
    const params = useParams()

    const { Theme } = store.Theme

    const handleChange = async (
      e: { value: string; label: string },
      index: number
    ) => {
      const id = store.RouteStore.currentDataId
      const listItems = store.TableStore.detailsData.checkListItems
      console.log(toJS(listItems))
      const item = listItems[e.value]
      if (
        (item.selected && e.value === currentStep.value) ||
        (!item.selected && e.value === nextStep.value) ||
        index + 1 === table.length
      ) {
        listItems[e.value] = {
          date: new Date().toISOString(),
          username: store.UserStore.user.length
            ? store.UserStore.user
            : 'No data',
          selected: !listItems[e.value].selected,
        }
        const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`
        const data = {
          checkListItems: listItems,
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
            'Access-Control-Allow-Credentials': 'true',
          },
        }
        try {
          await axios.post(url, data, config)
        } catch (e) {
          removeUserNameField(listItems)
          await axios.post(url, { checkListItems: null }, config)
          console.log(url, { checkListItem: toJS(listItems) }, config)
          await axios.post(url, { checkListItems: listItems }, config)
        }

        await store.TableStore.fetchDetailsData(params.id)
      } else
        toast('You try to modify element outside of the queue!', {
          type: 'warning',
        })
    }
    return (
      <HeaderDropDown {...Theme.proformsDetailsStyles.headerDropDown}>
        {table.map((e, index) => {
          return (
            <DropDownItem
              onClick={async () => {
                await handleChange(e, index)
              }}
              key={index}
              active={
                store.TableStore.detailsData.checkListItems &&
                store.TableStore.detailsData.checkListItems[e.value].selected
              }
              {...Theme.proformsDetailsStyles.dropDownItem}
            >
              {e.label}
            </DropDownItem>
          )
        })}
      </HeaderDropDown>
    )
  }
)
export default TasksDropDown
