import { observer } from 'mobx-react'
import {
  ButtonCont,
  Label,
  PrimaryButton,
  Setting,
  SettingsCategory,
  StyledLink,
} from '../../../../pages/settings/settings.styles'
import { useContext } from 'react'
import { StoreContext } from '../../../../App'

export const ThemeSetterComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  return (
    <SettingsCategory>
      <h1>Theme options:</h1>

      <Setting>
        <h2>Themes List:</h2>
        {store.Theme.themeKeys &&
          store.Theme.themeKeys.map((themeKey) => (
            <h2 key={themeKey}>
              <Label>
                {themeKey.charAt(0).toUpperCase() + themeKey.slice(1)}:
              </Label>
              <ButtonCont>
                <StyledLink to={'/'}>
                  <PrimaryButton
                    onClick={() => {
                      store.Theme.setTheme(themeKey)
                      store.Theme.activateThemeModification(true)
                    }}
                    {...Theme.buttons.primaryButton}
                  >
                    Edit
                  </PrimaryButton>
                </StyledLink>
                <PrimaryButton
                  onClick={() => store.Theme.setTheme(themeKey)}
                  {...Theme.buttons.primaryButton}
                >
                  Select
                </PrimaryButton>
                <PrimaryButton
                  inactive={themeKey === 'default'}
                  onClick={() => {
                    store.Theme.deleteTheme(themeKey)
                  }}
                  {...Theme.buttons.primaryButton}
                >
                  Delete
                </PrimaryButton>
              </ButtonCont>
            </h2>
          ))}
        <PrimaryButton
          onClick={() => store.Theme.activateThemeModification(true)}
          key='themecreator'
          {...Theme.buttons.primaryButton}
        >
          <StyledLink to={'/'}>Create a new theme</StyledLink>
        </PrimaryButton>
      </Setting>
    </SettingsCategory>
  )
})
