/* eslint-disable */
import { observer } from 'mobx-react'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { SelectInfo } from 'rc-menu/es/interface'
import { Tooltip } from '@mui/material'
import Fade from '@mui/material/Fade'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'

import '../../../styles/RcMenu.css'
import {
  HeaderOptionWrap,
  HeaderRightInner,
  HeaderRight,
  HeaderTitle,
  InnerHeader,
  Resize,
} from './TableGroupHeader.styles'
import {
  IconArrowDown,
  IconArrowRight,
  IconArrowsSort,
  IconArrowUp,
  IconChevronDown,
  IconSortAscending,
  IconSortDescending,
  IconSquareChevronDown,
} from '@tabler/icons-react'
import FilterIcon from '../../../assets/icons/tableFilter.svg'
import { HexColorPicker, RgbaStringColorPicker } from 'react-colorful'
import {
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
} from '../table/Table.styles'
import { Input } from '../../settings/tags/edits/colorInput/ColorInput.styles'
import { PredefinedColors } from '../../settings/tags/edits/colorInput/ColorField/ColorField.component'

interface Props extends PropsWithChildren {
  header: any
  //setSortBy: (val: any) => void
  columnWidth: boolean
  change: boolean
  toggleChange: (val: boolean) => void
  index: number
  setColumnOrder: (updater: Array<string>) => void
  keys: any
  identifier: string
  width?: number
}

const TableHeaderOptions: FC<Props> = observer(
  ({
    header,
    //setSortBy,
    columnWidth,
    change,
    toggleChange,
    index,
    setColumnOrder,
    keys,
    identifier,
  }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [triggerChange, setTriggerChange] = useState<boolean>(false)
    useEffect(() => {
      toggleChange(!change)
    }, [
      //header.column.columnDef.headerCustomOptions.settings.wrapText,
      //header.column.columnDef.headerCustomOptions.settings.color,
      triggerChange,
      //keys,
    ])

    return (
      <>
        <InnerHeader
          selected={header.colSpan > 1}
          {...Theme.tableStyles.innerHeader}
        >
          {header.id !== 'selection' && header.id !== '_options' ? (
            <Tooltip
              title={
                header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )
              }
              placement='bottom'
              arrow
              TransitionComponent={Fade}
            >
              <HeaderTitle
                isResizing={store.TableStore.isResizing}
                selected={false}
                {...Theme.tableStyles.tableCont}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </HeaderTitle>
            </Tooltip>
          ) : (
            <HeaderTitle
              isResizing={store.TableStore.isResizing}
              selected={
                store.SlidingPanelState.selectedSlider ===
                `tableHeader-${index}-${identifier}`
              }
              {...Theme.tableStyles.tableCont}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </HeaderTitle>
          )}
          {header.id !== 'selection' && header.id !== '_options' && (
            <HeaderRight>
              {columnWidth && header.colSpan > 1 ? (
                <Resize
                  onDoubleClick={() => header.column.resetSize()}
                  onMouseDown={header.getResizeHandler()}
                  //onMouseMove={() => toggleChange(!change)}
                  onTouchStart={header.getResizeHandler()}
                  onMouseUp={() => {
                    store.TableStore.setIsResizing(false)
                  }}
                  {...Theme.tableStyles.resize}
                />
              ) : (
                <></>
              )}
            </HeaderRight>
          )}
        </InnerHeader>
      </>
    )
  }
)
export default TableHeaderOptions
