/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Row } from '@tanstack/react-table'

import { hiddenColumns } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './RemoveOrder.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'

export interface EditProductsProps extends PropsWithChildren {
  tableName: string
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  url: string
  fetchData: () => void
}

const RemoveRenderedProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const handleSave = async () => {
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    store.TableStore.setLoading(false)
    if (id) {
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      if (props.tableName === 'currencies') {
        const data = store.StoreSettingsStore.data.filter(
          (e: any) => e.id !== id
        )
        console.log(props.url, data, config)
        await axios.post(props.url, data, config)
      } else {
        await axios.delete(`${props.url}/${id}`, config)
      }
      await store.SlidingPanelState.setVisibility(false)
      await setTimeout(() => store.TableStore.fetchData(), 2000)
      await toast('Success!', {
        type: 'success',
        autoClose: 5000,
      })
      await props.fetchData()
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{`Do you want to remove this ${props.name}?`}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleSave()}
          active={true}
          {...Theme.buttons.primaryButton}
        >
          {'CONFIRM'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default RemoveRenderedProduct
