/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ValidateArray,
} from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './CreateGeneric.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { socket } from '../../socket/socket'
import { DataInput } from '../dataInput/DataInput.component'
import axios from '../../axiosconfig'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'

export interface CreateProps extends PropsWithChildren {
  tableName: string
  url: string
  columns: any[]
  toggleClick?: () => void
  name: string
  fetchData: () => void
}

const getEmptyValue = (value: string, e: any) => {
  switch (value) {
    case 'text':
    case 'date':
    case 'tagSearch':
    case 'rootSearch':
    case 'groupSearch':
      return ''
    case 'number':
      return 0
    case 'color':
      return '#000000'
    case 'languageObject':
      return {}
    case 'stringArraySelect':
    case 'salesChannels':
      return []
    case 'select':
    case 'selectBool':
      return e.editCustomOptions.selectValues.length
        ? e.editCustomOptions.selectValues[0].value
        : ''
    default:
      return ''
  }
}
const CreateGeneric: (props: CreateProps) => JSX.Element = (
  props: CreateProps
) => {
  const store = useContext(StoreContext)
  const { SlidingPanelState } = store
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [lockChecked, setLockChecked] = useState(true)
  const [locked, setLocked] = useState(false)
  const [lockedUser, setLockedUser] = useState('')
  const [triggerChange, setTriggerChange] = useState(false)
  const [changedObject, setChangedObject] = useState(false)

  const divRef = useRef<any>(null)

  const initialState = [
    ...props.columns
      .filter((e) => e.editCustomOptions.editType && e.id)
      .map((e) => {
        return {
          header: e.id,
          value: getEmptyValue(e.editCustomOptions.editType, e),
          error: '',
          rules: ValidateArray[e.id] ? ValidateArray[e.id] : [],
        }
      }),
  ]
  console.log(initialState)
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const getUrl = (id: string | undefined) => {
    switch (props.tableName) {
      case 'currencies':
        return `${props.url}${id}`
      default:
        return `${props.url}/${id}`
    }
  }
  const handleSave = async () => {
    let fetchObject: any = {}

    form.map((e, index) => {
      if (
        props.columns.find((f: any) => f.id === e.header)?.editCustomOptions
          .editable
      )
        fetchObject = {
          [e.header]: e.value,
          ...fetchObject,
        }
    })

    store.SlidingPanelState.setVisibility(false)

    const data = store.TableStore.formatRequestData(fetchObject)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }

    try {
      await axios.post(props.url, data, config)
      await props.fetchData()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setInputColumns(Math.ceil((90 * initialState.length) / (height * 0.8)))
  }, [height])

  useEffect(() => {
    let changed = true
    form.map((e, index) => {
      const editOptions = props.columns.find(
        (f: any) => f.id === e.header
      )?.editCustomOptions

      const isMandatory =
        editOptions && editOptions.hasOwnProperty('mandatory')
          ? editOptions.mandatory
          : false
      const type = editOptions.type
      if (e.value !== getEmptyValue(type, e) && isMandatory) {
        changed = false
        return
      }
    })
    /*form.map((e, index) => {
                                                                                                                                                                                                                                                                                                                                                          if (e.error !== '') {
                                                                                                                                                                                                                                                                                                                                                            changed = false
                                                                                                                                                                                                                                                                                                                                                            return
                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                        })*/
    setChangedObject(changed)
  }, [triggerChange])

  return (
    <Container {...Theme.editProductsStyles.container}>
      {lockChecked && !locked ? (
        <>
          <InnerCont ref={divRef} cols={inputColumns}>
            {form.length
              ? form.map((e, index) => {
                  const customOptions = props.columns.find(
                    (col) => col.id === e.header
                  ).editCustomOptions
                  if (customOptions.editable && e.header !== 'equal')
                    return (
                      <DataInputGeneric
                        type={'create'}
                        vals={{ header: e.header, value: e.value }}
                        editOptions={customOptions}
                        key={`data-input--${index}`}
                        index={index}
                        form={form}
                        setForm={handleSetForm}
                        triggerChange={() => setTriggerChange(!triggerChange)}
                      />
                    )
                })
              : null}
          </InnerCont>
          <Footer {...Theme.editProductsStyles.styledFooter}>
            <SecondaryButton
              onClick={() => store.SlidingPanelState.setVisibility(false)}
              {...Theme.buttons.secondaryButton}
            >
              {'CANCEL'}
            </SecondaryButton>
            <PrimaryButton
              //onClick={() => changedObject && handleSave()}
              onClick={() => handleSave()}
              active={true}
              {...Theme.buttons.primaryButton}
            >
              {'SAVE'}
            </PrimaryButton>
          </Footer>
        </>
      ) : (
        <>
          {locked ? (
            <>This order is currently edited by {lockedUser}</>
          ) : (
            <>Checking order status</>
          )}
        </>
      )}
    </Container>
  )
}
export { CreateGeneric, getEmptyValue }
