/* eslint-disable */
import { observer } from 'mobx-react'
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { StoreContext } from '../../App'
import { hiddenColumns } from '../../columnsConfig'
import { validate } from '../../static/validate'
import {
  Checkbox,
  Container,
  EqualDiv,
  ErrorMessage,
  FilterLabel,
  Input,
  InputCont,
  StyledSelect,
} from './DataInputGeneric.styles'
import { ColorInput } from '../settings/tags/edits/colorInput/ColorInput.component'
import { ObjectInput } from '../settings/tags/edits/objectInput/ObjectInput.component'
import SearchTagComponent from '../settings/tags/edits/searchTag/SearchTag.component'
import { StringSelectInput } from '../settings/tags/edits/arraySelect/ArraySelect.component'
import ArrayObjectInput from '../settings/tags/edits/arrayObjectInput/ArrayObjectInput.component'
import { toJS } from 'mobx'
import SearchTagNewComponent from '../settings/tags/edits/searchTagNew/SearchTagNew.component'

interface InnerProps extends PropsWithChildren {
  type: string
  vals: { header: string; value: any }
  editOptions: {
    editable: boolean
    editType?: string
    mandatory?: boolean
    selectValues?: { value: string; label: string }[]
    arrayValues?: string[]
    dateFormat?: string
    mandatoryLanguage?: string
  }
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
  }>
  index: number

  setForm: (
    val: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>,
  ) => void
  triggerChange?: () => void
}

export const DataInputGeneric = observer(
  ({
    type,
    vals,
    index,
    form,
    setForm,
    triggerChange,
    editOptions,
  }: InnerProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const mandatory = type === 'create' && editOptions.mandatory === true
    const [active, setActive] = useState(mandatory)
    const formEqual = form.find((e: any) => e.header === 'equal')
    const formEqualIndex = form.findIndex((e) => e.header === 'equal')

    const changeHandler = (val: any) => {
      const error = validate(form[index].rules, val)
      const tempForm = form
      tempForm[index] = {
        header: vals.header,
        value: val,
        error: error,
        rules: form[index].rules,
      }
      //console.log(toJS(tempForm[index].value))
      setForm(tempForm)
    }

    const getInputType = () => {
      switch (editOptions.editType) {
        case 'text':
        default:
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value || ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                editOptions.editType === 'number'
                  ? changeHandler(Number(el.target.value))
                  : changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'number':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value !== null ? form[index].value : ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                editOptions.editType === 'number'
                  ? changeHandler(Number(el.target.value))
                  : changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'date':
        case 'datetime-local':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                changeHandler(
                  store.TranslationsState.dateDeformatter(
                    el.target.value,
                    editOptions.editType,
                    editOptions.dateFormat,
                  ),
                )
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'numberEqual':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={'number'}
              disabled={!active}
              activeStyle={active}
              value={form[index].value !== null ? form[index].value : ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'color':
          return (
            <ColorInput
              vals={form[index]}
              index={index}
              form={form}
              setForm={setForm}
            />
          )
        case 'filter':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value.label || ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                const tempForm = form[index].value
                tempForm.label = el.target.value
                changeHandler(tempForm)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'languageObject':
          return (
            <ObjectInput
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
              data={form[index].value}
              defaultHeader={editOptions.mandatoryLanguage || 'EN'}
              notCutLang={
                //@ts-ignore
                editOptions.mandatoryLanguage?.length > 3 ? true : false
              }
            />
          )
        case 'tagSearch':
          return (
            <SearchTagNewComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'tag'}
            />
          )
        case 'rootSearch':
          return (
            <SearchTagComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'root'}
            />
          )
        case 'groupSearch':
          return (
            <SearchTagComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'group'}
            />
          )
        case 'stringArraySelect':
          return (
            <StringSelectInput
              data={form[index].value}
              dataList={editOptions.arrayValues || []}
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
            />
          )
        case 'salesChannels':
          return (
            <ArrayObjectInput
              data={form[index].value}
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
            />
          )
        case 'select':
          return (
            <StyledSelect
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              disabled={!active}
              activeStyle={active}
              onChange={(el) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
            >
              {editOptions.selectValues?.map((e: any, i: number) => (
                <option
                  key={`option-=${i}-${index}-${e.label}`}
                  value={e.value}
                  selected={vals.value !== null && vals.value == e.value}
                >
                  {e.label}
                </option>
              ))}
            </StyledSelect>
          )
        case 'selectBool': {
          const value = vals.value == true
          return (
            <StyledSelect
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              disabled={!active}
              activeStyle={active}
              onChange={(el) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
            >
              <option value={'true'} selected={value.toString() === 'true'}>
                {'True'}
              </option>
              <option value={'false'} selected={value.toString() === 'false'}>
                {'False'}
              </option>
            </StyledSelect>
          )
        }
      }
    }

    const getCheckbox = () => {
      if (mandatory) return false
      switch (editOptions.editType) {
        case 'text':
        case 'number':
        case 'date':
        case 'datetime-local':
        case 'select':
        case 'selectBool':
        case 'numberEqual':
        case 'filter':
          return true
        default:
          return false
      }
    }

    const getEqual = () => {
      switch (editOptions.editType) {
        case 'numberEqual':
          return true
        default:
          return false
      }
    }

    return (
      <Container>
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {/*{locales[`${store.RouteStore.currentPage}Columns`][vals.header] ||
              vals.header}*/}
          {vals.header + (mandatory ? '*' : '')}
        </FilterLabel>
        <InputCont>
          {getInputType()}
          {getCheckbox() ? (
            <Checkbox
              type={'checkbox'}
              name={`${form[index].header}`}
              checked={active}
              onChange={() => {
                setActive(!active)
                store.TableStore.toggleDataInput()
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledCheckbox}
            />
          ) : (
            <></>
          )}
          {getEqual() && formEqual && formEqualIndex ? (
            <EqualDiv>
              <Checkbox
                type={'checkbox'}
                name={`${form[index].header}`}
                checked={formEqual ? formEqual.value : false}
                onChange={(e) => {
                  const tempForm = form
                  tempForm[formEqualIndex].value = e.currentTarget.checked
                  setForm(tempForm)
                  if (triggerChange) {
                    triggerChange()
                  }
                }}
                {...Theme.editProductsStyles.styledCheckbox}
              />
              <p>{'Equal'}</p>
            </EqualDiv>
          ) : (
            <></>
          )}
        </InputCont>
        {/*{active ? (
          <ErrorMessage {...Theme.editProductsStyles.errorMessage}>
            {JSON.stringify(form[index])}
          </ErrorMessage>
        ) : (
          <></>
        )}*/}
      </Container>
    )
  },
)
