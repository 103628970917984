import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../../App'
import Table from '../../../TableGeneric/table/Table.component'
import { ArrayDisplayCont } from '../Tags.styles'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'

export const GroupTagsTable = observer(() => {
  const store = useContext(StoreContext)

  const columns = {
    id: {
      canFilter: false,
      canSort: false,
      editable: false,
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    colour: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'color',
    },
    priority: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,

      editType: 'number',
    },
    description: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    roots: {
      Cell: (cell: any) => (
        <ArrayDisplayCont>
          {cell.getValue() &&
            cell
              .getValue()
              .map((value: any, index: number) => (
                <div key={`${index}-value-name`}>{value.groupID}</div>
              ))}
        </ArrayDisplayCont>
      ),
      editable: false,
      canSort: false,
      editType: 'stringArraySelect',
      arrayValues: store.TagsStore.avaliableIndicies,
    },
  }

  return (
    <Table
      config={{
        tableName: 'grouptags',
        data: store.TagsStore.data || [],
        fetchData: () => store.TagsStore.getData(),
        columnHeaders: columns,
        displayColumnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayInfinitySwitch: false,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: 'Group',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/grouptag`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/grouptag/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/grouptag/remove`,
          assignTag: null,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Group Tags',
          subTitle: 'List of Group Tags',
        },
        breadcrumb: [],
        loaded: store.TagsStore.dataLoaded,
        pagination: store.TagsStore.pagination,
        setPagination: store.TagsStore.setPagination,
      }}
    />
  )
})
