import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'

export class ViewStore {
  rootStore
  columnOrder: string[] = []
  hiddenColumns: any = {}
  selectedView = ''
  columnColors: { column: string; color: string }[] = []
  columnBorder: { column: string; border: boolean }[] = []
  loaded = true

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setColumnOrder = (columnOrder: string[]) => {
    this.columnOrder = columnOrder
  }

  @action.bound setSelectedView = (selectedView: string) => {
    this.selectedView = selectedView
  }

  @action.bound setHiddenColumns = (tableName: string, hiddenColumns?: any) => {
    console.log(toJS(hiddenColumns))
    if (Object.keys(hiddenColumns).length) {
      this.hiddenColumns = hiddenColumns
      localStorage.setItem(
        `hiddenColumns-${tableName}`,
        JSON.stringify(hiddenColumns),
      )
    }
  }

  @action.bound setColor = (column: string, color: string) => {
    const columnIndex = this.columnColors.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnColors[columnIndex].color = color
    } else {
      this.columnColors.push({ column, color })
    }
  }

  @action.bound setBorder = (column: string, border: boolean) => {
    const columnIndex = this.columnBorder.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnBorder[columnIndex].border = border
    } else {
      this.columnBorder.push({ column, border })
    }
  }

  @action.bound getDefaultAction = (tableName: string) => {
    if (
      Object.prototype.hasOwnProperty.call(
        this.rootStore.UserStore.defaultAction,
        tableName,
      )
    )
      //@ts-ignore
      return this.rootStore.UserStore.defaultAction[tableName]
  }

  @action.bound clearValues = () => {
    this.columnOrder = []
    this.hiddenColumns = {}
    this.columnColors = []
    this.columnBorder = []
  }

  @action.bound saveView = (
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const viewObject = {
      name: name,
      user: toJS(this.rootStore.UserStore.user),
      tableName: tableName,
      tableSize: pagination || null,
      private: privateView,
      allowedUsers: [], //do dorobienia w zapisywaniu
      allowedGroups: [], //do dorobienia w zapisywaniu
      hiddenColumns: toJS(this.hiddenColumns),
      columnOrder: toJS(this.columnOrder),
      columnColors: toJS(this.columnColors),
      columnBorder: toJS(this.columnBorder),
      clipTextColumns: toJS(this.rootStore.TableStore.clipTextColumns),
      appliedPredefinedQuery: toJS(
        this.rootStore.TableStore.appliedPredefinedQuery,
      ),
      sort: toJS(this.rootStore.TableStore.sort),
      favoriteAction: toJS(this.getDefaultAction(tableName)),
      showSearchField: toJS(this.rootStore.TableStore.showSearchField),
      infinitySwitch: toJS(this.rootStore.TableStore.infinitySwitch),
    }
    localStorage.setItem(`viewObject-${name}`, JSON.stringify(viewObject))
  }

  @action.bound getViewsList = (withDelete: boolean) => {
    const viewsList: any[] = []
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key && key.startsWith('viewObject-')) {
        const view = JSON.parse(localStorage.getItem(key) || '{}')
        viewsList.push(view)
      }
    }
    const views = [
      { value: '', label: `` },
      ...viewsList.map((e: any) => {
        if (withDelete) {
          return {
            value: e.name,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
            delete: e.private,
          }
        } else
          return {
            value: e.name,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
          }
      }),
    ]
    return views
  }

  @action.bound applyView = async (
    selectedView: string,
    fetchData: () => void,
  ) => {
    this.loaded = false
    const view = JSON.parse(
      localStorage.getItem(`viewObject-${selectedView}`) || '{}',
    )
    this.hiddenColumns = view.hiddenColumns
    this.columnOrder = view.columnOrder
    this.columnColors = view.columnColors
    this.columnBorder = view.columnBorder
    this.rootStore.TableStore.clipTextColumns = view.clipTextColumns
    this.rootStore.TableStore.appliedPredefinedQuery =
      view.appliedPredefinedQuery
    this.rootStore.TableStore.sort = view.sort
    this.rootStore.TableStore.showSearchField = view.showSearchField
    this.rootStore.TableStore.infinitySwitch = view.infinitySwitch
    await fetchData()
    this.loaded = true
  }
}
