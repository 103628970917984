import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropDownGroupFilter,
  DropdownHeader,
  DropDownItem,
  FilterLabel,
  GroupCheckbox,
  Input,
  InputCont,
  PathCont,
  ShowGroupHeader,
  TextCont,
} from './SearchTagNew.styles'
import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from '../../../../../axiosconfig'
import { StoreContext } from '../../../../../App'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { DeleteButton } from '../objectInput/ObjectInput.styles'
import GoBack from '../../../../../assets/icons/go-back.svg'
import ArrowRight from '../../../../../assets/icons/arrow-right.svg'
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react'
import {
  GroupTagType,
  RootTagType,
  TagType,
} from '../../../../../types/TagTypes'

interface AddProductSearchProps {
  applySearch: (val: any) => void
  header: string
  clearInputValue?: boolean
  view: 'tag'
  customIndex?: string
}

function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

const SearchTagNewComponent = observer(
  ({
    applySearch,
    clearInputValue,
    view,
    header,
    customIndex,
  }: AddProductSearchProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [initialData, setinitialData] = useState<RootTagType[]>([])
    const [data, setData] = useState<any[]>([])
    const [groupTagsData, setGroupTagsData] = useState<GroupTagType[]>([])
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])
    const [displayedData, setDisplayedData] = useState<
      TagType[] | RootTagType[]
    >([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [secondTierTags, setSecondTierTags] = useState<boolean>(false)
    const [rootId, setRootId] = useState<string[]>([])
    //const [grouppedRootId, setGrouppedRootId] = useState<string[]>([])
    const [showGroups, setShowGroups] = useState<boolean>(false)
    //const [currentPage, setCurrentPage] = useState(0)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedProducts = async () => {
      const url =
        store.TagsStore.getUrlBasedOnView('data', undefined, 'root') +
        `?from=0&size=100`

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      const response = await axios.post(url, { skipChildren: false }, config)

      if (response.status === 200) {
        setSecondTierTags(false)
        const initialTags = response.data.tags.filter((e: RootTagType) =>
          e.indicesAllowed.includes(
            customIndex || store.TagsStore.getCurrentIndex(),
          ),
        )
        await setRootId([...initialTags.map((e: RootTagType) => e.id)])
        await setinitialData(initialTags)
        await setData(initialTags)
      }
    }

    const searchTagsByQuery = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tags_view?from=0&size=200`
      const data = {
        skipChildren: false,
        filterGroups: {
          rules: [
            {
              field: 'name.EN',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue.toString(),
            },
          ],
          combinator: 'and',
          not: false,
        },
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.post(url, data, config)

      await setData(
        response.data.tags.filter((e: TagType) => rootId.includes(e.root_id)),
      )
      await setSecondTierTags(true)
    }

    const searchGroupTags = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/grouptags_view?from=0&size=200`
      const data = {
        skipChildren: false,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.post(url, data, config)
      await setGroupTagsData(
        response.data.tags.map((e: GroupTagType) => {
          return { name: e.name.EN, id: e.id }
        }),
      )
    }

    const setSecondTier = async (rootTag: any) => {
      setSecondTierTags(true)
      const collectTags = (tags: TagType[], collectedTags: TagType[] = []) => {
        tags.forEach((tag) => {
          collectedTags.push(tag)
        })

        return collectedTags
      }

      const allTags = collectTags(
        // eslint-disable-next-line no-prototype-builtins
        rootTag.hasOwnProperty('tags') ? rootTag.tags : rootTag.children,
      )
      setData(allTags)
    }

    useOutsideClick(dropdownRef, () => {
      if (!isInputFocused) return
      setSecondTierTags(false)
      setData([])
      searchRecommendedProducts()
      setIsInputFocused(false)
    })

    useEffect(() => {
      searchGroupTags()
    }, [])

    useEffect(() => {
      if (!isInputFocused) return
      setData([])
      //setCurrentPage(0)
      searchRecommendedProducts()
    }, [isInputFocused])

    useEffect(() => {
      if (inputValue.length) {
        searchTagsByQuery()
      } else searchRecommendedProducts()
    }, [inputValue])

    useEffect(() => {
      if (selectedGroups.length && !secondTierTags) {
        console.log(
          data.filter(
            (e: any) =>
              Object.keys(e.name).length &&
              e.name.EN.toLowerCase().includes(inputValue.toLowerCase()) &&
              selectedGroups.includes(e.groupID),
          ),
        )
        setDisplayedData(() =>
          data.filter(
            (e: any) =>
              Object.keys(e.name).length &&
              e.name.EN.toLowerCase().includes(inputValue.toLowerCase()) &&
              selectedGroups.includes(e.groupID),
          ),
        )
      } else
        setDisplayedData(() =>
          data.filter(
            (e: any) =>
              Object.keys(e.name).length &&
              e.name.EN.toLowerCase().includes(inputValue.toLowerCase()),
          ),
        )
    }, [data, selectedGroups])

    return (
      <Container ref={dropdownRef}>
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {'Search Tag'}
        </FilterLabel>
        <InputCont>
          <Input
            value={inputValue}
            id={`input-${store.RouteStore.currentPage}-${header}`}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Search for ${view}...`}
            onFocus={() => setIsInputFocused(true)}
          />
          <DeleteButton
            onClick={() => {
              setSecondTierTags(false)
              setData([])
              searchRecommendedProducts()
              setIsInputFocused(false)
            }}
          >
            <img src={ClearFilters} />
          </DeleteButton>
        </InputCont>
        {isInputFocused ? (
          secondTierTags ? (
            <DropDown>
              <DropdownHeader>
                <div></div>
                <div>Tags view</div>
                <div
                  onClick={() => {
                    setSecondTierTags(false)
                    setData(initialData)
                  }}
                >
                  <img src={GoBack} />
                </div>
              </DropdownHeader>
              {displayedData.map((e: any, index: number) => {
                const lowerCaseInput = inputValue.toLowerCase()
                const lowerCaseName = e.name.EN.toLowerCase()
                const startIndex = lowerCaseName.indexOf(lowerCaseInput)
                return (
                  <DropDownItem
                    key={`search-dropdown=${index}`}
                    onClick={() => {
                      applySearch(e)
                      setInputValue(e.id)
                      if (clearInputValue) {
                        setInputValue('')
                      }
                    }}
                  >
                    {/*<ImageCont>
                      <img src={e.image} alt={e.name}/>
                  </ImageCont>*/}
                    <TextCont>
                      <PathCont>{e.path ? e.path.EN : ''}</PathCont>
                      {!inputValue.length ? (
                        <p>{e.name.EN}</p>
                      ) : (
                        <p>
                          {e.name.EN.slice(0, startIndex)}
                          <b>
                            {e.name.EN.slice(
                              startIndex,
                              startIndex + inputValue.length,
                            )}
                          </b>
                          {e.name.EN.slice(
                            startIndex + inputValue.length,
                            e.name.EN.length,
                          )}
                        </p>
                      )}
                    </TextCont>
                    {e.children && e.children.length ? (
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          setSecondTier(e)
                        }}
                      >
                        <img src={ArrowRight} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </DropDownItem>
                )
              })}
            </DropDown>
          ) : (
            <DropDown>
              <DropdownHeader>
                <div></div>
                <div>Root view</div>
                <div></div>
              </DropdownHeader>
              <ShowGroupHeader onClick={() => setShowGroups(!showGroups)}>
                Show Group Filters{' '}
                {showGroups ? (
                  <IconArrowUp size='16px' />
                ) : (
                  <IconArrowDown size='16px' />
                )}
              </ShowGroupHeader>
              {showGroups ? (
                <DropDownGroupFilter>
                  {groupTagsData.map((e: any, index: number) => {
                    return (
                      <GroupCheckbox key={`group-filter-${index}`}>
                        <input
                          type={'checkbox'}
                          checked={selectedGroups.includes(e.id)}
                          onChange={(val) => {
                            if (val.target.checked)
                              setSelectedGroups([...selectedGroups, e.id])
                            else
                              setSelectedGroups(
                                selectedGroups.filter(
                                  (groups) => groups !== e.id,
                                ),
                              )
                          }}
                        />
                        <p>{e.name}</p>
                      </GroupCheckbox>
                    )
                  })}
                </DropDownGroupFilter>
              ) : (
                <></>
              )}
              {displayedData.map((e: any, index: number) => (
                <DropDownItem onClick={() => setSecondTier(e)} key={index}>
                  <b
                    style={{
                      width: '80%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {e.name.EN}
                  </b>
                  {e.tags && e.tags.length ? (
                    <div
                      onClick={(event) => {
                        event.stopPropagation()
                        setSecondTier(e)
                      }}
                    >
                      <img src={ArrowRight} />
                    </div>
                  ) : (
                    <></>
                  )}
                </DropDownItem>
              ))}
            </DropDown>
          )
        ) : null}
      </Container>
    )
  },
)

export default SearchTagNewComponent
