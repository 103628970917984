import { action, makeAutoObservable } from 'mobx'
import { mainTheme } from '../static/themes/main.theme'
import { RootStore } from './Root.store'
import axios from '../axiosconfig'

/* eslint-disable */
export class ThemeStore {
  rootStore
  Theme = mainTheme
  themeKeys: string[] = []
  themeName = 'default'
  Navigation = 'top'
  sideBarActive = false
  sideBarExpanded = false
  activeThemeMod = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.initThemes()
    this.sideBarActive = false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  @action.bound
  async initThemes() {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/themes/list?showAll=true`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.get(url, config)
    this.themeKeys = response.data.themes
    this.setTheme(this.themeName)
  }

  @action.bound switchTheme(theme: any, themeName?: string) {
    this.Theme = theme
    if (themeName) this.themeName = themeName
  }

  @action.bound
  async setTheme(themeName: string) {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/themes/${themeName}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.get(url, config)
    this.themeName = themeName
    this.Theme = response.data.doc
  }

  @action.bound
  async deleteTheme(themeName: string) {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/themes/${themeName}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.delete(url, config)
    this.themeName = themeName
    this.Theme = response.data.doc
  }

  @action.bound switchNavigation(nav: string) {
    this.Navigation = nav
  }

  @action.bound setSideBarActive(sideBarActive: boolean) {
    this.sideBarActive = sideBarActive
  }

  @action.bound setSideBarExpanded(sideBarExpanded: boolean) {
    this.sideBarExpanded = sideBarExpanded
  }

  @action.bound activateThemeModification(activeThemeMod: boolean) {
    this.activeThemeMod = activeThemeMod
  }
}
