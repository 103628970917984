/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'

import { hiddenColumns } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './AddMultipleSupplierProduct.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import AddProductSearchComponent from '../addProductSearch/AddProductSearch.component'
import { FilterLabel, Input } from '../dataInput/DataInput.styles'
import { validate } from '../../static/validate'
import { socket } from '../../socket/socket'

const AddMultipleSupplierProduct: () => JSX.Element = () => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [changedObject, setChangedObject] = useState(false)
  const columns = ['quantity', 'productID']
  const initialState = [
    {
      header: 'quantity',
      value: '1',
      error: '',
      rules: ['positive'],
    },
    {
      header: 'productID',
      value: '',
      error: '',
      rules: ['required', 'length'],
    },
  ]
  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const applySearch = (product: any) => {
    const tempForm = [...form]
    tempForm[1].value = product.id
    setForm([...tempForm])
  }

  const handleSave = async () => {
    const data = {
      filterGroups: {
        rules: [
          {
            field: 'id',
            operator: '=',
            valueSource: 'value',
            value: form[1].value,
          },
        ],
        combinator: 'and',
        not: false,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=1&from=0`
    const response = await axios.post(url, data, config)
    if (response.data.count) {
      const product = response.data.products[0]
      const keys = store.TableStore.selectedRowId
      const fetchPromises = keys.map((key) => {
        const postUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${key}`
        const postData = {
          id: key,
          products: {
            [form[1].value]: {
              ProductID: product.id,
              Name: product.name,
              ProductCode: product.code,
              SupplierName: product.producer,
              SupplierDeliveryCode: '',
              SupplierOrderId: '',
              OrderLink: '',
              Notes: '',
              ProducerName: product.producer,
              OrderedQuantity: form[0].value,
              SupplierDeliveryDate: '',
              DeliveredQuantity: '0',
              DeliveredDate: '',
            },
          },
        }
        const formattedData = store.TableStore.formatRequestData(postData)
        return axios.post(postUrl, formattedData, config)
      })
      await Promise.all(fetchPromises)
      await toast('Success!', { type: 'success' })
      await store.SlidingPanelState.setVisibility(false)
      await keys.map((key: any) => {
        socket.emit('editOrder', {
          id: key,
          user: store.UserStore.user,
          page: store.TableStore.getApiRequestFromUrl(),
        })
      })
      //await setTimeout(async () => await store.TableStore.fetchData(), 3000)
    } else {
      toast('Response with this orderID does not exist!', { type: 'error' })
    }
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setChangedObject(changed)
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <AddProductSearchComponent applySearch={applySearch} />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {'Quantity'}
        </FilterLabel>
        <Input
          type={'number'}
          value={form[0].value}
          onChange={(e) => {
            const updatedForm = [...form]
            const error = validate(form[0].rules, e.target.value)
            updatedForm[0] = { ...form[0], value: e.target.value, error: error }

            setForm(updatedForm)
          }}
          activeStyle={true}
          {...Theme.editProductsStyles.styledInput}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => changedObject && handleSave()}
          active={changedObject}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default AddMultipleSupplierProduct
