import styled from 'styled-components'
import { darken } from 'polished'

/* eslint-ignore */

interface StyledInputInterface {
  activeStyle: boolean
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const Input = styled.input<StyledInputInterface>`
  height: 40px;
  width: 180px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '16px')};
  background-color: ${(props) => (props.activeStyle ? '#ffffff' : '#f5f5f5')};
  border: ${(props) =>
    props.activeStyle
      ? props.activeBgColor || '1px solid #D6D6D6'
      : props.inactiveBgColor || '1px solid #575757'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`
export const Select = styled.select<StyledInputInterface>`
  height: 40px;
  width: 100px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '16px')};
  background-color: ${(props) => (props.activeStyle ? '#ffffff' : '#f5f5f5')};
  border: ${(props) =>
    props.activeStyle
      ? props.activeBgColor || '1px solid #D6D6D6'
      : props.inactiveBgColor || '1px solid #575757'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

export const ObjectRow = styled.div`
  display: flex;
`

interface PrimaryButtonProps {
  padding?: string
  border?: string
  bgColor?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
  letterSpacing?: string
}

/* eslint-disable */

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 12px;
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  margin-left: auto;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  margin-bottom: 8px;
  transform: scale(0.8);
  transition:
    background-color 0.3s,
    transform 0.2s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(0.83);
  }

  &:active {
    transform: scale(0.78);
  }
`

export const DeleteButton = styled.div`
  margin: 8px;
  margin-top: 16px;
`
