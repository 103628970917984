import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../../App'
import Table from '../../../TableGeneric/table/Table.component'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'
import TableCollectionTagsComponent from '../../../TableGeneric/tableCollectionTags/TableCollectionTags.component'
import TableBool from '../../../TableGeneric/TableBool/TableBool.component'
/* eslint-disable */
export const CollectionTagsTableComponent = observer(() => {
  const store = useContext(StoreContext)

  const columns = {
    id: {
      canFilter: false,
      editable: false,
      mandatory: false,
      editType: 'text',
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    tags: {
      Cell: (cell: any) => (
        <TableCollectionTagsComponent tags={cell.getValue()} />
      ),
      canFilter: false,
      editable: false,
    },
    active: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    description: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
  }

  useEffect(() => {
    store.TagsStore.getCollectionsData()
    return () => {
      store.TagsStore.clearCollectionsData()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'collectiontags',
        data: store.TagsStore.collectionsData || [],
        fetchData: () => store.TagsStore.getCollectionsData(),
        columnHeaders: columns,
        displayColumnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayInfinitySwitch: false,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: 'Collection',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/remove`,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/assign`,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Collections',
          subTitle: 'List of Collections',
        },
        breadcrumb: [],
        loaded: store.TagsStore.dataLoaded,
        pagination: store.TagsStore.pagination,
        setPagination: store.TagsStore.setPagination,
      }}
    />
  )
})
