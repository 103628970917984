import Table from '../../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'

import { LanguageBasedCell } from '../tags/edits/languageBasedCell/LanguageBasedCell.component'
import TableBool from '../../TableGeneric/TableBool/TableBool.component'
/* eslint-disable */

const CategoriesTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
    },
    parent_id: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'text',
    },
    desc_short: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'en_GB',
    },
    desc_long: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'en_GB',
    },
    active: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    active_positioning: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    positioning_priority: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'number',
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'en_GB',
    },
    note: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'text',
    },
  }

  useEffect(() => {
    store.CategoriesStore.fetchData()
    return () => store.CategoriesStore.clearData()
  }, [])

  return (
    <Table
      config={{
        tableName: 'categories',
        data: store.CategoriesStore.data || [],
        fetchData: () => store.CategoriesStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: 'Category',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/categories`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/categories/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/categories/remove`,
          assignTag: null,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Categories View',
          subTitle: 'List of Categories',
        },
        breadcrumb: [],
        loaded: store.CategoriesStore.loaded,
        pagination: store.CategoriesStore.pagination,
        setPagination: store.CategoriesStore.setPagination,
      }}
    />
  )
})
export default CategoriesTable
