/* eslint-disable */
import React, { useContext } from 'react'
import { Cell } from '@tanstack/react-table'
import { observer } from 'mobx-react'

import { StoreContext } from '../../App'

import TableDownloadBlob from '../TableGeneric/TableDownloadBlob/TableDownloadBlob.component'
import Table from '../TableGeneric/table/Table.component'
import TableTransactionDataComponent from '../TableGeneric/tableTransactionData/TableTransactionData.component'
import TableTags from '../TableGeneric/tableTags/TableTags.component'
import TableNewTagsComponent from '../TableGeneric/tableNewTags/TableNewTags.component'
import TableCategories from '../TableGeneric/tableCategories/TableCategories.component'
import TableBool from '../TableGeneric/TableBool/TableBool.component'

const BankTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore

  let columns: { [key: string]: any } = {
    id: {
      editable: false,
      canFilter: true,
    },
    type: {
      editable: false,
      canFilter: true,
    },
    user: {
      editable: false,
      canFilter: true,
    },
    date: {
      editable: false,
      canFilter: true,
      editType: 'date',
    },
    exported: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: true,
      editable: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    transactionData: {
      Cell: (cell: any) => (
        <TableTransactionDataComponent transactionData={cell.getValue()} />
      ),
      editable: false,
      canFilter: true,
      canSort: false,
    },
    export: {
      Cell: (cell: any) => (
        <>
          {cell.getValue() && Object.keys(cell.getValue()).length ? (
            <div>
              <p>Last Export: {cell.getValue().LastUser || 'No users'}</p>
              <p>Last Export: {cell.getValue().LastDate || 'No exports'}</p>
            </div>
          ) : (
            <div></div>
          )}
        </>
      ),
      editable: false,
      canSort: false,
      canFilter: true,
    },
  }

  if (store.UserStore.features.includes('tagmgm')) {
    columns = {
      ...columns,
      object_tags: {
        Cell: (cell: any) => (
          <TableNewTagsComponent key={cell.getValue()} tags={cell.getValue()} />
        ),
        canFilter: true,
        canSort: false,
        editable: false,
        editType: 'select',
        selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
          value: tag.id,
          label: tag.name.EN,
        })),
      },
    }
  }

  return (
    <Table
      config={{
        tableName: 'bank',
        data: store.TableStore.data || [],
        fetchData: () => store.TableStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: tableStore.configColumnVisibility,
        allowColumnResize: tableStore.configAllowColumnResize,
        displaySearch: tableStore.configDisplaySearch,
        displaySelect: tableStore.configDisplaySelect,
        displayExpand: tableStore.configDisplayExpand,
        displayTooltip: tableStore.configDisplayTooltip,
        displayEntry: tableStore.configDisplayEntry,
        displayPaginator: tableStore.configDisplayPaginator,
        displayInfinitySwitch: false,

        allowSort: tableStore.configAllowSort,
        bank: tableStore.configBankFeatures,
        actions: {
          display: true,
          label: 'Transaction',
          edit: null,
          create: null,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/transactions/remove`,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          custom: [],
        },
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Bank Transactions',
          subTitle: 'List of bank transactions',
        },
        breadcrumb: ['Panel', 'Bank Transactions'],
        loaded: store.TableStore.loaded,
        pagination: tableStore.pagination.bank,
        setPagination: tableStore.setPagination,
      }}
    ></Table>
  )
})
export default BankTable
