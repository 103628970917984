/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ReplaceEmptyStringsForZeros,
  ValidateArray,
} from '../../columnsConfig'
import { StoreContext } from '../../App'
import { DataInput } from '../dataInput/DataInput.component'

import {
  InnerCont,
  Footer,
  PrimaryButton,
  SecondaryButton,
  LoadingWrapper,
} from './EditMultipleProductsByQuery.styles'
import { Container, Wrap } from '../../styles/Loading.styles'
import { socket } from '../../socket/socket'
import { toast } from 'react-toastify'

interface formInterface {
  keys: Array<string>
  tableName: string
}

const EditMultipleProductsByQuery = observer(
  ({ keys, tableName }: formInterface) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const divRef = useRef<any>()
    const [inputColumns, setInputColumns] = useState(1)
    const [triggerChange, setTriggerChange] = useState(false)
    const [changedObject, setChangedObject] = useState(false)
    const { width, height } = useWindowDimensions()

    const defaultValues: any = {
      stockAmountInPackageBool: true,
    }

    const initialState = [
      ...keys.map((e) => {
        return {
          header: e,
          value: defaultValues.hasOwnProperty(e) ? defaultValues[e] : '',
          error: '',
          rules: ValidateArray[e] ? ValidateArray[e] : [],
        }
      }),
    ]
    const [form, setForm] = useState(initialState)

    const handleSetForm = (
      tempForm: Array<{
        header: string
        value: string
        error: string
        rules: Array<string>
      }>
    ) => {
      setForm([...tempForm])
    }

    useEffect(() => {
      let changed = false
      form.map((e, index) => {
        if (e.value !== '') {
          changed = true
          return
        }
      })
      form.map((e, index) => {
        if (e.error !== '') {
          changed = false
          return
        }
      })
      setChangedObject(changed)
    }, [triggerChange])

    const getUrl = () => {
      switch (tableName) {
        case 'stock':
        default:
          return 'products_update'
        case 'customerorders':
          return 'customer_orders_update'
      }
    }

    const handleSave = async () => {
      let bodyData: any = {}
      form.map((f: any, index) => {
        const selected =
          divRef.current.querySelector(`input[name="${f.header}"]`) &&
          divRef.current.querySelector(`input[name="${f.header}"]`).checked
        if (selected) {
          if (ReplaceEmptyStringsForNulls.includes(f.header) && f.value === '')
            bodyData = { ...bodyData, [f.header]: null }
          if (f.header === 'status' && f.value === '')
            bodyData = { ...bodyData, [f.header]: '0' }
          else bodyData = { ...bodyData, [f.header]: f.value }
        }
      })

      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/${getUrl()}`

      const data = {
        updateJSON: store.TableStore.formatRequestData(bodyData),
        includeIds: store.TableStore.selectedRowId,
        filterGroups: {
          rules: [],
          combinator: 'or',
          not: false,
        },
        excludeIds: [],
        searchAll: `**`,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user,
        },
      }
      const res = await axios.post(url, data, config)

      store.TableStore.setLoading(false)
      console.log(res)
      setTimeout(async () => await store.TableStore.fetchData(), 2000)
      store.SlidingPanelState.setVisibility(false)
      toast(`${res.data.updated} of ${res.data.total} has been changed`, {
        type: 'success',
      })
      if (res.data.failures.length > 0) {
        toast(`Failed to update: ${JSON.stringify(res.data.failures)}`, {
          type: 'error',
        })
      }
      setTimeout(() => store.TableStore.fetchData(), 2000)
      setTimeout(
        () =>
          store.TableStore.selectedRowId.forEach((e: any) => {
            socket.emit('editOrder', {
              id: e,
              user: store.UserStore.user,
              page: store.TableStore.getApiRequestFromUrl(),
            })
          }),
        2000
      )
    }

    useEffect(() => {
      setInputColumns(
        Math.ceil(
          (90 * keys.filter((e) => !hiddenColumns.includes(e)).length) /
            (height * 0.8)
        )
      )
    }, [height])

    useEffect(() => {
      let changed = false
      form.map((e, index) => {
        if (e.value !== initialState[index].value) {
          changed = true
          return
        }
      })
      form.map((e, index) => {
        if (e.error !== '') {
          changed = false
          return
        }
      })
    }, [form])

    return (
      <div>
        <InnerCont cols={inputColumns} ref={divRef}>
          {keys.map((e, index) => (
            <DataInput
              vals={{ header: e, value: '' }}
              form={form}
              index={index}
              setForm={handleSetForm}
              key={`edit-multiple-${index}`}
              triggerChange={() => setTriggerChange(!triggerChange)}
            />
          ))}
        </InnerCont>
        <Footer {...Theme.editProductsStyles.styledFooter}>
          <SecondaryButton
            onClick={() => store.SlidingPanelState.setVisibility(false)}
            {...Theme.buttons.secondaryButton}
          >
            {'CANCEL'}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              if (changedObject) handleSave()
            }}
            active={changedObject}
            {...Theme.buttons.primaryButton}
          >
            {'SAVE'}
          </PrimaryButton>
        </Footer>
      </div>
    )
  }
)
export default EditMultipleProductsByQuery
