import styled from 'styled-components'

export const Tooltip = styled.span`
  position: absolute;
  background-color: black;
  color: white;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px;
  border-radius: 4px;
  z-index: 10;
  left: calc(100% + 6px);
`

interface ButtonProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  paddingLeft?: string
  paddingTop?: string
  paddingRight?: string
  margin?: string
  color?: string
}

export const Button = styled.button<ButtonProps>`
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;

  outline: none;
  background: transparent;
  border: none;
  text-transform: capitalize;
  cursor: pointer;
  font-stretch: 110% !important;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '12px')};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '1.5px')};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '12px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '18px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.44px'};
  color: ${(props) => (props.color ? props.color : 'inherit')};

  img {
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  }

  &:hover + ${Tooltip} {
    display: block;
  }
`
