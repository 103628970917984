import { GenericTreeComponent } from './genericTree/GenericTree.component'

import { useContext } from 'react'
import { StoreContext } from '../../App'
import SearchCategoryComponent from './categories/searchCategories/SearchCategory.component'
import axios from 'axios'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

const CategoriesTreeGeneric = observer(() => {
  const store = useContext(StoreContext)

  return (
    <GenericTreeComponent
      data={store.CategoriesStore.data}
      setData={() => store.CategoriesStore.fetchAllData(false)}
      mainDesc={'Category'}
      lang={'en_GB'}
      firstLevelChild={'children'}
      secondLevelChild={'children'}
      firstLevelParent={'parent_id'}
      secondLevelParent={'parent_id'}
      baseUrl={`${process.env.REACT_APP_BASE_URL}experience/v1/categories/`}
      searchComponent={(applySearch: any) => (
        <SearchCategoryComponent
          applySearch={applySearch}
          header={'Search Category'}
        />
      )}
      dropFunction={async (currentTagData: any, draggedTagData: any) => {
        await store.TagsStore.setTreeLoaded(false)
        const url = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${draggedTagData.id}`
        const data = {
          parent_id: currentTagData.id,
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        await axios.post(url, data, config)
        await store.CategoriesStore.fetchAllData(false)
        await setTimeout(async () => {
          await store.TagsStore.setTreeLoaded(true)
        }, 1000)
      }}
      dropPlusFunction={async (currentTagData: any, draggedTagData: any) => {
        await store.TagsStore.setTreeLoaded(false)
        const url1 = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${draggedTagData.id}`
        const data1 = {
          parent_id: currentTagData.parent_id,
        }
        const url2 = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${currentTagData.id}`
        const data2 = {
          parent_id: draggedTagData.id,
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        await axios.post(url1, data1, config)
        await axios.post(url2, data2, config)
        await store.CategoriesStore.fetchAllData(false)
        await setTimeout(async () => {
          await store.TagsStore.setTreeLoaded(true)
        }, 1000)
      }}
      addExistingLeftFunction={async (
        currentId: string,
        newId: string,
        parentId: string,
      ) => {
        await store.TagsStore.setTreeLoaded(false)
        const url1 = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${newId}`
        const data1 = {
          parent_id: parentId,
        }
        const url2 = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${currentId}`
        const data2 = {
          parent_id: newId,
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        await axios.post(url1, data1, config)
        await axios.post(url2, data2, config)
        await setTimeout(async () => {
          await store.CategoriesStore.fetchAllData(false)
          await store.TagsStore.setTreeLoaded(true)
          toast('Category Added!', {
            type: 'success',
          })
        }, 1000)
      }}
      addExistingRightFunction={async (currentId: string, newId: string) => {
        await store.TagsStore.setTreeLoaded(false)
        const url = `${process.env.REACT_APP_BASE_URL}experience/v1/categories/${newId}`
        const data = {
          parent_id: currentId,
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        await axios.post(url, data, config)
        await setTimeout(async () => {
          await store.CategoriesStore.fetchAllData(false)
          await store.TagsStore.setTreeLoaded(true)
          toast('Category Added!', {
            type: 'success',
          })
        }, 1000)
      }}
    />
  )
})
export default CategoriesTreeGeneric
