import { GenericTreeComponent } from './genericTree/GenericTree.component'
import SearchTagNewComponent from './tags/edits/searchTagNew/SearchTagNew.component'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { StoreContext } from '../../App'

/* eslint-disable */
const TagTreeGeneric = () => {
  const store = useContext(StoreContext)

  return (
    <GenericTreeComponent
      data={store.TagsStore.data}
      setData={() => {
        store.TagsStore.setView('root')
        store.TagsStore.getData()
      }}
      mainDesc={'Tag'}
      lang={'EN'}
      firstLevelChild={'tags'}
      secondLevelChild={'children'}
      firstLevelParent={'root_id'}
      secondLevelParent={'parent'}
      baseUrl={`${process.env.REACT_APP_BASE_URL}experience/v1/tag/`}
      searchComponent={(applySearch: any) => (
        <SearchTagNewComponent
          applySearch={applySearch}
          view={'tag'}
          header={'Search Tag'}
        />
      )}
      dropFunction={async (
        currentTagData: any,
        draggedTagData: any,
        firstLevel: boolean,
      ) => {
        await store.TagsStore.setTreeLoaded(false)
        const data = {
          parent: !firstLevel ? currentTagData.id : '',
        }
        toast('Tag moved successfully.')
        await store.TagsStore.editTag(data, draggedTagData.id, 'tag')
        await store.TagsStore.getData()
        await store.TagsStore.setTreeLoaded(true)
      }}
      dropPlusFunction={async (currentTagData: any, draggedTagData: any) => {
        await store.TagsStore.setTreeLoaded(false)
        await store.TagsStore.insertTag(
          draggedTagData.id,
          currentTagData.parent,
          currentTagData.id,
          {},
        )
        await store.TagsStore.getData()
        await store.TagsStore.setTreeLoaded(true)
      }}
      addExistingLeftFunction={async (
        currentId: string,
        newId: string,
        parentId: string,
      ) => {
        await store.TagsStore.insertTag(newId, parentId, currentId, {})
      }}
      addExistingRightFunction={async (currentId: string, newId: string) => {
        const data = {
          parent: currentId,
        }
        await store.TagsStore.editTag(data, newId, 'tag')
      }}
    />
  )
}
export default TagTreeGeneric
