import { observer } from 'mobx-react'
import React, { PropsWithChildren, useContext } from 'react'
import { StoreContext } from '../../../../../App'
import { hiddenColumns } from '../../../../../columnsConfig'
import { validate } from '../../../../../static/validate'
import { InputCont, Input } from './ColorInput.styles'
import { HexColorPicker } from 'react-colorful'
import { PredefinedColors } from './ColorField/ColorField.component'

interface InnerProps extends PropsWithChildren {
  vals: { header: string; value: string }
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
  }>
  index: number
  setForm: (
    val: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>,
  ) => void
  triggerChange?: () => void
}

export const ColorInput = observer(
  ({ vals, index, form, setForm, triggerChange }: InnerProps) => {
    const store = useContext(StoreContext)

    /* eslint-disable */
    const changeHandler = (val: string) => {
      const error = validate(form[index].rules, val)
      const tempForm = form
      tempForm[index] = {
        header: vals.header,
        value: val,
        error: error,
        rules: form[index].rules,
      }
      console.log(tempForm[index])
      setForm(tempForm)
    }

    const shouldNotBeVisible = (header: string) => {
      let flag = false
      hiddenColumns.map((e: string) => {
        if (header === e) flag = true
      })
      return flag
    }

    if (!shouldNotBeVisible(vals.header))
      return (
        <InputCont>
          <Input
            activeStyle={true}
            type='text'
            id={`input-${store.RouteStore.currentPage}-${vals.header}`}
            value={vals.value}
            onChange={(e) => changeHandler(e.target.value)}
          />
          <HexColorPicker
            color={vals.value}
            onChange={(e) => changeHandler(e)}
          />
          <PredefinedColors onSelectColor={(color) => changeHandler(color)} />
        </InputCont>
      )
    else return <></>
  },
)
