import { observer } from 'mobx-react'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import { ReactComponent as StarEmpty } from '../../../assets/icons/star-empty.svg'
import { ReactComponent as StarFull } from '../../../assets/icons/star-full.svg'
import {
  ActionEdit,
  ActionList,
  Container,
  SingleAction,
  StarComponent,
  StyledIconDotsVertical,
  SvgCont,
} from './TableActions.styles'
import { IconDotsVertical, IconMenu2 } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import RemoveOrderComponent from '../../removeOrder/RemoveOrder.component'
import EditGenericComponent from '../../editGeneric/EditGeneric.component'
import AssignTagGeneric from '../../assignTagGeneric/AssignTagGeneric.component'
import AddSupplierProduct from '../../addSupplierProduct/AddSupplierProduct.component'
import AssignCollectionGenericComponent from '../../assignCollectionGeneric/AssignCollectionGeneric.component'
import AssignOrderLineTagAllTagsComponent from '../../settings/tags/edits/assignOrderLineTag/AssignOrderLineTagAllTags.component'
import AssignOrderLineTagGenericComponent from '../../assignOrderLineTagGeneric/AssignOrderLineTagGeneric.component'
import useOutsideClick from '../../../methods/methods/useOutSideClick'

interface Props extends PropsWithChildren {
  tableName: string
  actions: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  }
  columns: Array<string>
  row: any
  loaded: boolean
  fetchData: () => void
}

const TableMoreActions: FC<Props> = observer(
  ({ tableName, actions, columns, row, loaded, fetchData }: Props) => {
    const store = useContext(StoreContext)
    const containerRef = useRef<any>(null)
    const { Theme } = store.Theme

    const [showActions, setShowActions] = useState(false)
    const [listedActions, setListedActions] = useState(actions)
    const [showMore, setShowMore] = useState(false)
    const keys = [...row.getAllCells().map((e: any) => e.column.id)]
    const values = [
      ...keys.map((e, index) => ({
        header: keys[index],
        value: row.getValue(e),
      })),
    ]
    //console.log(values)
    const [favoriteAction, setFavoriteAction] = useState('')

    const changeFavouriteAction = async (action: string) => {
      setShowActions(true)
      const tempDefaultActions: any = store.UserStore.defaultAction
      tempDefaultActions[tableName] = action
      store.SlidingPanelState.setSelectedSlider('')
      await store.UserStore.updateDefaultSettings(
        store.UserStore.defaultLanguage,
        store.UserStore.defaultTheme,
        store.UserStore.defaultAction
      )
      await store.SlidingPanelState.setSelectedSlider('')
    }

    useOutsideClick(containerRef, () => {
      setShowActions(false)
      setShowMore(false)
    })

    useEffect(() => {
      if (
        Object.prototype.hasOwnProperty.call(
          store.UserStore.defaultAction,
          tableName
        )
      )
        //@ts-ignore
        setFavoriteAction(store.UserStore.defaultAction[tableName])
    }, [JSON.stringify(store.UserStore.defaultAction)])

    useEffect(() => {
      if (!showMore) {
        const tempActions = {
          display: true,
          label: actions.label,
          edit: null,
          create: null,
          remove: null,
          assignTag: null,
          custom: [],
        }
        const fields = ['edit', 'create', 'remove', 'assignTag']
        if (favoriteAction.length) {
          if (fields.includes(favoriteAction))
            //@ts-ignore
            tempActions[favoriteAction] = actions[favoriteAction]
          //@ts-ignore
          else tempActions.custom = [favoriteAction]
        } else {
          let count = 0
          fields.forEach((e) => {
            //@ts-ignore
            if (count === 0 && actions[e] !== null) {
              //@ts-ignore
              tempActions[e] = actions[e]
              count = count + 1
            }
          })
          if (count === 0) tempActions.custom = actions.custom[0]
        }
        setListedActions(tempActions)
      } else setListedActions(actions)
    }, [favoriteAction, showMore])

    if (listedActions)
      return (
        <>
          <Container ref={containerRef}>
            <SvgCont>
              <StyledIconDotsVertical
                size='16px'
                onClick={() => setShowActions(!showActions)}
              />
            </SvgCont>
            <ActionList
              sliding={store.SlidingPanelState.visibility}
              showActions={showActions}
              {...Theme.tableMoreActionsStyles.actionList}
            >
              {showActions && (
                <>
                  {listedActions.edit && (
                    <SingleAction
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          `sliding-edit-${row.id}`
                        )
                      }
                    >
                      <StarComponent>
                        {favoriteAction === 'edit' ? (
                          <StarFull />
                        ) : (
                          <StarEmpty
                            onClick={(e) => {
                              e.stopPropagation()
                              changeFavouriteAction('edit')
                            }}
                          />
                        )}
                      </StarComponent>
                      {'Edit ' + actions.label}
                    </SingleAction>
                  )}
                  {listedActions.remove && (
                    <SingleAction
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          `sliding-remove-${row.id}`
                        )
                      }
                    >
                      <StarComponent>
                        {favoriteAction === 'remove' ? (
                          <StarFull />
                        ) : (
                          <StarEmpty
                            onClick={(e) => {
                              e.stopPropagation()
                              changeFavouriteAction('remove')
                            }}
                          />
                        )}
                      </StarComponent>
                      {'Remove ' + actions.label}
                    </SingleAction>
                  )}
                  {listedActions.assignTag && (
                    <>
                      <SingleAction
                        onClick={() =>
                          store.SlidingPanelState.setSelectedSlider(
                            `sliding-assigntag-${row.id}`
                          )
                        }
                      >
                        <StarComponent>
                          {favoriteAction === 'assignTag' ? (
                            <StarFull />
                          ) : (
                            <StarEmpty
                              onClick={(e) => {
                                e.stopPropagation()
                                changeFavouriteAction('assignTag')
                              }}
                            />
                          )}
                        </StarComponent>
                        {'Assign tag to ' + actions.label}
                      </SingleAction>
                    </>
                  )}
                  {listedActions.custom.includes('assignCollection') && (
                    <SingleAction
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          `sliding-assigncollection-${row.id}`
                        )
                      }
                    >
                      <StarComponent>
                        {favoriteAction === 'assignCollection' ? (
                          <StarFull />
                        ) : (
                          <StarEmpty
                            onClick={(e) => {
                              e.stopPropagation()
                              changeFavouriteAction('assignCollection')
                            }}
                          />
                        )}
                      </StarComponent>
                      {'Assign Collection to ' + actions.label}
                    </SingleAction>
                  )}
                  {listedActions.custom.includes('getPackageDocuments') && (
                    <>
                      <SingleAction
                        onClick={() =>
                          store.PackagesStore.exportPackageDocuments(row, true)
                        }
                      >
                        <StarComponent>
                          {favoriteAction === 'getPackageDocuments' ? (
                            <StarFull />
                          ) : (
                            <StarEmpty
                              onClick={(e) => {
                                e.stopPropagation()
                                changeFavouriteAction('getPackageDocuments')
                              }}
                            />
                          )}
                        </StarComponent>
                        {'Export package documents'}
                      </SingleAction>
                      <SingleAction
                        onClick={() =>
                          store.PackagesStore.exportPackageDocuments(row, false)
                        }
                      >
                        <StarComponent>
                          {favoriteAction === 'exportLabels' ? (
                            <StarFull />
                          ) : (
                            <StarEmpty
                              onClick={(e) => {
                                e.stopPropagation()
                                changeFavouriteAction('exportLabels')
                              }}
                            />
                          )}
                        </StarComponent>
                        {'Export Labels'}
                      </SingleAction>
                    </>
                  )}
                  {listedActions.custom.includes('addSupplierProduct') && (
                    <SingleAction
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          `sliding-addsupplierproduct-${row.id}`
                        )
                      }
                    >
                      <StarComponent>
                        {favoriteAction === 'addSupplierProduct' ? (
                          <StarFull />
                        ) : (
                          <StarEmpty
                            onClick={(e) => {
                              e.stopPropagation()
                              changeFavouriteAction('addSupplierProduct')
                            }}
                          />
                        )}
                      </StarComponent>
                      {'Add Product to Supplier Order'}
                    </SingleAction>
                  )}
                  {listedActions.custom.includes('orderLineAllTags') && (
                    <SingleAction
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          `sliding-orderLineAllTags-${row.id}`
                        )
                      }
                    >
                      <StarComponent>
                        {favoriteAction === 'orderLineAllTags' ? (
                          <StarFull />
                        ) : (
                          <StarEmpty
                            onClick={(e) => {
                              e.stopPropagation()
                              changeFavouriteAction('orderLineAllTags')
                            }}
                          />
                        )}
                      </StarComponent>
                      {'Assign Tag to all OrderLines'}
                    </SingleAction>
                  )}
                  {!showMore ? (
                    <SingleAction onClick={() => setShowMore(true)}>
                      <b>{'Show more actions...'}</b>
                    </SingleAction>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ActionList>
          </Container>
          {actions.edit && (
            <SlidingPanel
              contentId={`sliding-edit-${row.id}`}
              title={`Edit ${actions.label}`}
            >
              <EditGenericComponent
                tableName={tableName}
                url={actions.edit}
                fetchData={fetchData}
                row={row}
                columns={values}
                toggleClick={() => setShowActions(false)}
                name={'Edit'}
              />
            </SlidingPanel>
          )}
          {actions.remove && (
            <SlidingPanel
              contentId={`sliding-remove-${row.id}`}
              title={`Remove ${actions.label}`}
            >
              <RemoveOrderComponent
                id={'test'}
                tableName={tableName}
                row={row}
                fetchData={fetchData}
                columns={values}
                toggleClick={() => setShowActions(false)}
                name={actions.label}
                url={actions.remove}
              />
            </SlidingPanel>
          )}
          {actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-assigntag-${row.id}`}
              title={`Assign tag to ${actions.label}`}
            >
              <AssignTagGeneric
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
                url={actions.assignTag}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('assignCollection') && actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-assigncollection-${row.id}`}
              title={`Assign Collection to ${actions.label}`}
            >
              <AssignCollectionGenericComponent
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
                url={actions.assignTag}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('addSupplierProduct') && (
            <SlidingPanel
              contentId={`sliding-addsupplierproduct-${row.id}`}
              title={`Add Product to ${actions.label}`}
            >
              <AddSupplierProduct
                id={'test'}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('orderLineAllTags') && actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-orderLineAllTags-${row.id}`}
              title={`Assign Tag to all Products`}
            >
              <AssignOrderLineTagGenericComponent
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
              />
            </SlidingPanel>
          )}
        </>
      )
    else return <></>
  }
)

export default TableMoreActions
