import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropdownHeader,
  DropDownItem,
  FilterLabel,
  Input,
  InputCont,
  TextCont,
} from './SearchCategory.styles'
import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from '../../../../axiosconfig'
import { StoreContext } from '../../../../App'
import ClearFilters from '../../../../assets/icons/clear-filters.svg'
import { DeleteButton } from '../../tags/edits/objectInput/ObjectInput.styles'
import GoBack from '../../../../assets/icons/go-back.svg'

/* eslint-disable */
interface AddProductSearchProps {
  applySearch: (val: any) => void
  header: string
  clearInputValue?: boolean
  customIndex?: string
}

function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

const SearchCategoryComponent = observer(
  ({ applySearch, clearInputValue, header }: AddProductSearchProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [initialData, setinitialData] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    //const [displayedData, setDisplayedData] = useState<any[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedProducts = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/categories_view?size=100&from=0`

      const data = {
        skipChildren: false,
        flatten: true,
      }

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      const response = await axios.post(url, data, config)

      if (response.status === 200) {
        const initialTags = response.data.categories
        await setinitialData(initialTags)
        await setData(initialTags)
      }
    }

    const filterByQuery = async () => {
      setData(
        initialData.filter(
          (cat) => cat.name.en_GB && cat.name.en_GB.includes(inputValue),
        ),
      )
    }

    useOutsideClick(dropdownRef, () => {
      if (!isInputFocused) return
      setData([])
      searchRecommendedProducts()
      setIsInputFocused(false)
    })

    useEffect(() => {
      if (!isInputFocused) return
      setData([])
      //setCurrentPage(0)
      searchRecommendedProducts()
    }, [isInputFocused])

    useEffect(() => {
      if (inputValue.length) {
        filterByQuery()
      } else searchRecommendedProducts()
    }, [inputValue])

    return (
      <Container ref={dropdownRef}>
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {'Search Category'}
        </FilterLabel>
        <InputCont>
          <Input
            value={inputValue}
            id={`input-${store.RouteStore.currentPage}-${header}`}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Search for category...`}
            onFocus={() => setIsInputFocused(true)}
          />
          <DeleteButton
            onClick={() => {
              setData([])
              searchRecommendedProducts()
              setIsInputFocused(false)
            }}
          >
            <img src={ClearFilters} />
          </DeleteButton>
        </InputCont>
        {isInputFocused ? (
          <DropDown>
            <DropdownHeader>
              <div></div>
              <div>Categories view</div>
              <div
                onClick={() => {
                  setData(initialData)
                }}
              >
                <img src={GoBack} />
              </div>
            </DropdownHeader>
            {data.map((e: any, index: number) => {
              const lowerCaseInput = inputValue.toLowerCase()
              const lowerCaseName = e.name.en_GB.toLowerCase()
              const startIndex = lowerCaseName.indexOf(lowerCaseInput)
              return (
                <DropDownItem
                  key={`search-dropdown=${index}`}
                  onClick={() => {
                    applySearch(e)
                    setInputValue(e.id)
                    setIsInputFocused(false)
                    if (clearInputValue) {
                      setInputValue('')
                    }
                  }}
                >
                  <TextCont>
                    {!inputValue.length ? (
                      <p>{e.name.en_GB}</p>
                    ) : (
                      <p>
                        {e.name.en_GB.slice(0, startIndex)}
                        <b>
                          {e.name.en_GB.slice(
                            startIndex,
                            startIndex + inputValue.length,
                          )}
                        </b>
                        {e.name.en_GB.slice(
                          startIndex + inputValue.length,
                          e.name.en_GB.length,
                        )}
                      </p>
                    )}
                  </TextCont>
                </DropDownItem>
              )
            })}
          </DropDown>
        ) : null}
      </Container>
    )
  },
)

export default SearchCategoryComponent
