/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './RemoveTag.styles'

import { StoreContext } from '../../../../../App'
import axios from '../../../../../axiosconfig'
import { toast } from 'react-toastify'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../../../../styles/Loading.styles'

export interface EditProductsProps extends PropsWithChildren {
  columns: Array<{ header: string; value: string }>
  baseUrl: string
  mainDesc: string
  setData: () => void
}

const RemoveRenderedProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [loaded, setLoaded] = useState(true)

  const handleSave = async () => {
    setLoaded(false)
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    if (id) {
      const url = `${props.baseUrl}remove/${id}`
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user,
        },
      }
      console.log(props.baseUrl)
      console.log(url)
      const response = await axios.delete(url, config)
      await store.SlidingPanelState.setVisibility(false)
      await setTimeout(() => props.setData(), 2000)
      await toast('Success!', {
        type: 'success',
        autoClose: 5000,
      })
      await setLoaded(true)
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{`Do you want to remove this ${props.mainDesc}?`}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        {loaded ? (
          <PrimaryButton
            onClick={() => handleSave()}
            active={true}
            {...Theme.buttons.primaryButton}
          >
            {'CONFIRM'}
          </PrimaryButton>
        ) : (
          <PrimaryButton active={false} {...Theme.buttons.primaryButton}>
            <LoadingContainer>
              <Wrap></Wrap>
            </LoadingContainer>
          </PrimaryButton>
        )}
      </Footer>
    </Container>
  )
}
export default RemoveRenderedProduct
