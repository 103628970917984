import styled from 'styled-components'

interface MainTitleProps {
  fontWeight?: number
  lineHeight?: string
  fontSize?: string
  color?: string
}

export const HeadlineWrapper = styled.div``

export const MainTitle = styled.p<MainTitleProps>`
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '2rem')};
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '26px')};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`
