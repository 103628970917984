import { observer } from 'mobx-react'
import { createContext, useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Header from './components/header/Header.component'
import Toast from './components/toast/Toast.component'
import Bank from './pages/bank/bank.component'
import Orders from './pages/orders/orders.component'
import ProformsComponent from './pages/proforms/proforms.component'
import ProformsDetails from './pages/proformsDetails/proformsDetails.component'
import Settings from './pages/settings/settings.component'
import Stock from './pages/stock/stock.component'
import NotFound from './pages/notFound/NotFound.component'
import { rootStore } from './stores/Root.store'
import {
  Content,
  Layout,
  OutSideLayOut,
  OutSideUnauthenticated,
} from './styles/LayoutStyles'
import MainPageComponent from './pages/mainPage/mainPage.component'
import ReportsComponent from './pages/reports/reports.component'
import Packing from './pages/packing/packing.component'
import { socket } from './socket/socket'
import PackingDetailsComponent from './pages/packingDetails/packingDetails.component'
import SwitchToTopButtonComponent from './components/switchToTopButton/SwitchToTopButton.component'
import { toast } from 'react-toastify'
import StandardErrorBoundary from './components/errorBoundary/ErrorBoundary'
import { ThemeCreatorComponent } from './components/settings/themeCreator/ThemeCreator.component'
import ThemeVerify from './pages/themeVerify/ThemeVerify.component'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { PackagesTableComponent } from './components/settings/packages/PackagesTable.component'
import { Container as LoadingContainer, Wrap } from './styles/Loading.styles'
import SupplierDetailsComponent from './pages/SupplierDetails/SupplierDetails.component'
import { toJS } from 'mobx'

interface GlobalStyleProps {
  bgColor: string
  color: string
  inputColor: string
}

/* eslint-disable */
const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    body {
        margin: 0;
        padding: 0;
        background-color: ${(props) =>
          props.bgColor ? props.bgColor : '#f9fafb'};
        color: ${(props) => (props.color ? props.color : '#000000')};

        input {
            background-color: ${(props) =>
              props.inputColor ? props.inputColor : '#ffffff'};
        }
    }

    #ze-snippet {
        @media print {
            display: none;
        }
    }
`

export const StoreContext = createContext(rootStore)

const App = observer(() => {
  //const { instance, accounts } = useMsal()
  const store = useContext(StoreContext)
  const [fontsReady, setFontsReady] = useState(false)
  const { user, isAuthenticated } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()
  const { Theme, Navigation } = store.Theme
  const [isConnected, setIsConnected] = useState(socket.connected)

  const arraysAreDifferent = (arr1: object[], arr2: object[]) => {
    if (arr1.length !== arr2.length) return true

    return arr1.some((obj1, index) => {
      const obj2 = arr2[index]
      const obj1Json = JSON.stringify(obj1)
      const obj2Json = JSON.stringify(obj2)
      return obj1Json !== obj2Json
    })
  }

  useEffect(() => {
    if (socket.id) store.RouteStore.setSocketId(socket.id)
  }, [isConnected])

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    } else {
      if (user && user?.name && user?.profilePictureUrl) {
        console.log(user)
        store.UserStore.authenticate(
          user.name,
          user.profilePictureUrl,
          user.id,
          user.accessToken,
          user,
        )
      }
    }
  }, [isAuthenticated, loginWithRedirect])

  useEffect(() => {
    socket.on('connect', () => setIsConnected(true))
    socket.on('pageLockChange', (pageState) => {
      store.RouteStore.setLockedValues(pageState)
    })
    socket.on('orderChange', (object) => {
      console.log(object)
      const newData = store.TableStore.data.map((e: any) =>
        e['id'] === object.id ? { ...e, ...object.data } : e,
      )
      console.log(newData)
      if (arraysAreDifferent(store.TableStore.data, newData)) {
        store.TableStore.setData(newData)
        toast(`Data in ${object.id} has been changed by ${object.user}`, {
          type: 'info',
        })
      }
    })
    return () => {
      socket.off('connect')
      socket.off('pageLockChange')
      socket.off('orderChange')
    }
  }, [])

  useEffect(() => {
    const checkFontsLoaded = () => {
      document.fonts.ready.then(() => {
        setFontsReady(true)
      })
    }

    if (document.fonts && document.fonts.status === 'loaded') {
      setFontsReady(true)
    } else {
      document.fonts.addEventListener('loadingdone', checkFontsLoaded)
    }

    return () => {
      document.fonts.removeEventListener('loadingdone', checkFontsLoaded)
    }
  }, [])

  useEffect(() => {
    console.log(toJS(store.ViewStore.hiddenColumns))
  }, [store.ViewStore.hiddenColumns])

  return (
    <>
      <StoreContext.Provider value={rootStore}>
        <StandardErrorBoundary user={store.UserStore.user}>
          <GlobalStyle
            color={Theme.global.global.color}
            bgColor={Theme.global.global.bgColor}
            inputColor={Theme.global.global.inputColor}
          />
          {isAuthenticated && store.UserStore.mainLoaded && fontsReady ? (
            <>
              <OutSideLayOut>
                <Layout
                  type={Navigation}
                  active={store.Theme.sideBarActive}
                  modifyMode={store.Theme.activeThemeMod}
                >
                  <Toast />
                  <SwitchToTopButtonComponent />
                  <Header
                    navType={Navigation}
                    setNavType={(navType) => {
                      store.Theme.switchNavigation(navType)
                    }}
                  />
                  <Content
                    sidebar={store.Theme.sideBarActive}
                    expanded={store.Theme.sideBarExpanded}
                    modifyMode={store.Theme.activeThemeMod}
                  >
                    <RouteList navType={Navigation} />
                  </Content>
                </Layout>
                {store.Theme.activeThemeMod ? <ThemeCreatorComponent /> : <></>}
              </OutSideLayOut>
            </>
          ) : (
            <OutSideUnauthenticated>
              <LoadingContainer>
                <Wrap></Wrap>
              </LoadingContainer>
            </OutSideUnauthenticated>
          )}
        </StandardErrorBoundary>
      </StoreContext.Provider>
    </>
  )
})

interface RouteListProps {
  navType: string
}

const RouteList = observer(({ navType }: RouteListProps) => {
  const store = useContext(StoreContext)
  const location = useLocation()

  const prepareFilters = () => {
    store.FiltersStore.fetchAllFilters()
    store.RouteStore.handlePageChange(
      location.pathname,
      window.location.search.substring(1),
    )
  }
  useEffect(() => {
    prepareFilters()
  }, [location.pathname])

  return (
    <Routes>
      <Route
        path={'/customerorders'}
        element={<ProformsComponent navType={navType} />}
      />
      <Route path={'/customerorders/:id'} element={<ProformsDetails />} />
      <Route path={'/stock'} element={<Stock />} />
      <Route path={'/supplierorders'} element={<Orders />} />
      <Route
        path={'/supplierorders/:id'}
        element={<SupplierDetailsComponent />}
      />
      <Route path={'/reports'} element={<ReportsComponent />} />
      <Route path={'/reports/:page'} element={<ReportsComponent />} />
      <Route path={'/admin'} element={<Settings />} />
      <Route path={'/admin/:category/:page'} element={<Settings />} />
      <Route path={'/bank'} element={<Bank />} />
      {/*<Route path={'/admin'} element={<Admin />} />*/}
      <Route path={'/packing'} element={<Packing />} />
      <Route path={'/packages'} element={<PackagesTableComponent />} />
      <Route path={'/themeverify'} element={<ThemeVerify />} />
      <Route path={'/packing/:id'} element={<PackingDetailsComponent />} />
      <Route path={'/'} element={<MainPageComponent />} />
      <Route path={'/*'} element={<NotFound />} />
    </Routes>
  )
})

export default App
