/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Row } from '@tanstack/react-table'

import { hiddenColumns } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './RemoveRenderedProduct.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import { socket } from '../../socket/socket'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  tableRow: string
}

const RemoveRenderedProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${props.tableRow}`
    const code = props.row
      .getAllCells()
      .find((e: any) => e.column.id === 'ProductID')?.value
    const data = {
      id: props.tableRow,
      lastEditDate: new Date().toISOString(),
      lastEditAuthor: store.UserStore.user,
      products: {
        [code]: null,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    if (response.status === 200) {
      toast('Product removed successfully', { type: 'success' })
      store.SlidingPanelState.setVisibility(false)
      socket.emit('editOrder', {
        id: props.tableRow,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
      /*setTimeout(() => {
                                                                                store.TableStore.fetchData()
                                                                              }, 2000)*/
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{'Do you want to remove this product?'}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleSave()}
          active={true}
          {...Theme.buttons.primaryButton}
        >
          {'CONFIRM'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default RemoveRenderedProduct
