/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ValidateArray,
} from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './EditTimeZones.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toJS } from 'mobx'
import TimeZoneComponent from './timeZone/TimeZone.component'
import { toast } from 'react-toastify'

export interface EditProductsProps extends PropsWithChildren {
  data: any[]
}

const EditGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { SlidingPanelState } = store
  const { Theme } = store.Theme
  const [data, setData] = useState(props.data)
  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/timezones`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, toJS(data), config)
    await axios.post(url, { timezones: data }, config)
    await store.SlidingPanelState.setVisibility(false)
    await toast('Success!', {
      type: 'success',
      autoClose: 5000,
    })
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <TimeZoneComponent data={data} handleChange={setData} />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          //onClick={() => changedObject && handleSave()}
          onClick={() => handleSave()}
          active={true}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default EditGeneric
