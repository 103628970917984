import { toJS } from 'mobx'
import {
  ChangeEvent,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  defaultOperators,
  QueryBuilder,
  ValueSelector,
  ValueEditor,
} from 'react-querybuilder'
import { StoreContext } from '../../App'
import '../../styles/querybuilder-custom.scss'
import axios from '../../axiosconfig'
import styled from 'styled-components'

interface Props extends PropsWithChildren {
  table: any
  keys: Array<any>
  query: any
  setQuery: any
}

const DisabledValueEditor = styled(ValueEditor)`
  background-color: #f5f5f5 !important;
`

const CustomValueEditor = (props: any, type: string) => {
  const { field, operator, value, handleOnChange } = props

  if (operator === 'between' || operator === 'not between') {
    const firstValue = value.split(',')[0] || ''
    const secondValue = value.split(',')[1] || ''
    const formattedValue = `${firstValue},${secondValue}`

    const handleFirstInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleOnChange(`${e.target.value},${secondValue}`)
    }

    const handleSecondInputChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      handleOnChange(`${firstValue},${e.target.value}`)
    }

    useEffect(() => {
      console.log('here')
    }, [])

    return (
      <>
        <input
          type={type}
          value={firstValue}
          onChange={handleFirstInputChange}
        />
        <span> and </span>
        <input
          type={type}
          value={secondValue}
          onChange={handleSecondInputChange}
        />
      </>
    )
  }
  if (!operator.length) return <DisabledValueEditor {...props} disabled />

  return <ValueEditor {...props} />
}

const CustomCombinator = (props: any) => {
  if (props.rules.length <= 1) {
    return <div />
  } else return <ValueSelector {...props} />
}

const GetOperators = (type: string) => {
  switch (type) {
    case 'text': {
      const operators = [
        '=',
        '!=',
        'contains',
        'beginsWith',
        'endsWith',
        'doesNotContain',
        'doesNotBeginWith',
        'doesNotEndWith',
        'null',
        'notNull',
      ]
      return [
        {
          name: '',
          label: 'Select operator!',
        },
        ...defaultOperators.filter((e: any) => operators.includes(e.name)),
      ]
    }
    case 'number':
    case 'numberEqual':
    case 'date':
    case 'datetime-local': {
      const operators = ['=', '!=', '<', '<=', '>', '>=', 'null', 'notNull']
      return [
        {
          name: '',
          label: 'Select operator!',
        },
        ...defaultOperators.filter((e: any) => operators.includes(e.name)),
      ]
    }
    case 'select':
    case 'selectBool': {
      const operators = ['=', '!=', 'null', 'notNull']
      return [
        {
          name: '',
          label: 'Select operator!',
        },
        ...defaultOperators.filter((e: any) => operators.includes(e.name)),
      ]
    }
    default:
      return [{ name: '', label: 'Select operator!' }, ...defaultOperators]
  }
}

const queryBuilder = (props: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const [producers, setProducers] = useState<string[]>([])

  console.log(props.table.getAllFlatColumns())
  const keys = [
    ...props.table
      .getAllFlatColumns()
      .filter(
        (e: any) =>
          e.columnDef.headerCustomOptions &&
          e.columnDef.headerCustomOptions.canFilter === true
      )
      .map((e: any) => e.id),
  ]
  console.log(keys)

  const fields: any = [
    ...keys.map((e) => {
      console.log(e)
      const column = props.table
        .getAllFlatColumns()
        .find((f: any) => f.id === e)?.columnDef.editCustomOptions
      if (
        column.editType &&
        (column.editType === 'date' || column.editType === 'datetime-local')
      ) {
        return {
          name: e,
          label: e || locales[`${store.RouteStore.currentPage}Columns`][e],
          inputType: column.editType,
          operators: GetOperators(column.editType),
        }
      }
      if (
        column.editType &&
        column.selectValues &&
        (column.editType === 'select' || column.editType === 'selectBool')
      ) {
        return {
          name: e,
          label: e,
          operators: GetOperators(column.editType),
          valueEditorType: 'select',
          values: [
            ...column.selectValues.map((f: any) => ({
              name: f.value,
              label: f.label,
            })),
          ],
        }
      } else
        return {
          name: e,
          operators: GetOperators(column.editType),
          label:
            // eslint-disable-next-line no-prototype-builtins
            locales.hasOwnProperty(`${store.RouteStore.currentPage}Columns`) &&
            locales[`${store.RouteStore.currentPage}Columns`][e]
              ? locales[`${store.RouteStore.currentPage}Columns`][e]
              : e,
        }
    }),
  ]
  const fetchProducers = async () => {
    const producersUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/producers_view?size=100&from=0`
    const producersResponse = await axios.post(
      producersUrl,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
    )
    const suppliers = [
      ...(await producersResponse.data.producers.map((e: any) => {
        return { name: e.name, label: e.name }
      })),
    ]

    await setProducers(suppliers)
  }

  const getType = (prop: any) => {
    const column = props.keys.find(
      (f: any) => f.id === prop.field
    ).editCustomOptions
    if (column.editType) return column.editType
    else return 'text'
  }

  useEffect(() => {
    fetchProducers()
  }, [])

  useEffect(() => {
    console.log(toJS(props.query))
  }, [toJS(props.query)])

  return (
    <QueryBuilder
      fields={fields}
      query={toJS(props.query)}
      onQueryChange={(q) => props.setQuery(q)}
      controlClassnames={{ queryBuilder: 'builder' }}
      controlElements={{
        combinatorSelector: CustomCombinator,
        //valueEditor: (props) => CustomValueEditor(props, getType(props)),
      }}
      operators={[{ name: '', label: 'Select operator!' }, ...defaultOperators]}
    />
  )
}
export default queryBuilder
