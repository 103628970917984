import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
/* eslint-disable */
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'
import MailIcon from '../../assets/icons/mail-icon.svg'
import PrintIcon from '../../assets/icons/print-icon.svg'
import {
  AddressBody,
  Body,
  Container,
  HeaderButton,
  HeaderCont,
  HeaderInner,
  HeaderList,
  HeaderTitle,
  LeftBody,
  RightBody,
  SecondaryButton,
  StyledImg,
  NavButton,
  PrintSelectedButton,
  HeaderParagraph,
  HeaderInnerRight,
  ButtonCont,
  ShippingCountry,
} from './SupplierDetails.styles'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'

import { StoreContext } from '../../App'
import DetailsDropDown from '../../components/orderDetails/detailsDropDown/DetailsDropDown.component'
import DetailsTable from '../../components/orderDetails/detailsTable/DetailsTable.component'
import ProductListTable from '../../components/orderDetails/ListOfProductsTable/ListOfProductsTable.component'
import {
  NextStepCont,
  PrimaryButtonCont,
  ActionsPrimaryButton,
  PrimaryButton,
  PrintFooter,
} from '../proformsDetails/ProformsDetails.styles'
import axios from '../../axiosconfig'
import { setDefaultDetails } from '../proformsDetails/proformsDetails.methods'
import TasksDropDownComponent from '../../components/orderDetails/tasksDropDown/TasksDropDown.component'
import FilterIcon from '../../assets/icons/filter-icon.svg'
import ChooseTagTree from '../../components/settings/tagsTree/chooseAddTag/ChooseTagTree.component'
import { SlidingPanel } from '../../components/slidingContainer/SlidingContainer.component'
import ActionButton from '../../components/orderDetails/actionButton/actionButton.component'

const ProformsDetails = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const params = useParams()

  const DetailsArray = [
    { header: 'Currency', value: 'currency' },
    { header: 'SupplierOrderId', value: 'supplierOrderId' },
    { header: 'Supplier', value: 'supplier' },
    { header: 'Creation Date', value: 'creationDate' },
    { header: 'Estimate Date', value: 'estimateDate' },
  ]

  useEffect(() => {
    store.TableStore.fetchSupplierData(params.id)
  }, [])

  return (
    <>
      <Container
        loading={store.TableStore.loaded}
        {...Theme.proformsDetailsStyles.container}
      >
        {store.TableStore.loaded ? (
          <>
            <HeaderCont>
              <Breadcrumb
                treeArray={['Panel', 'Customer Orders', 'Order Details']}
              />
              <HeaderList>
                <HeaderInner>
                  <HeaderTitle {...Theme.proformsDetailsStyles.headerTitle}>
                    {'Supplier Order'}: {params.id}
                  </HeaderTitle>
                  <ButtonCont>
                    {/*<BlobProvider
                      document={
                        <MainPrint printTable={true} packingTable={true} />
                      }
                    >
                      {({ blob, url, loading, error }: any) => {
                        const handlePrint2 = async () => {
                          const fileURL = URL.createObjectURL(blob)
                          const a: HTMLAnchorElement =
                            document.createElement('a')
                          a.href = fileURL
                          a.target = '_blank'
                          document.body.appendChild(a)
                          a.click()
                        }

                        return (
                          <HeaderButton
                            onClick={handlePrint2}
                            {...Theme.proformsDetailsStyles.headerButton}
                          >
                            <img src={PrintIcon} alt={'Print'} />
                            {locales.buttons.print}
                          </HeaderButton>
                        )
                      }}
                    </BlobProvider>
                    <BlobProvider
                      document={
                        <MainPrint printTable={false} packingTable={true} />
                      }
                    >
                      {({ blob, url, loading, error }: any) => {
                        const handlePrint2 = async () => {
                          const fileURL = URL.createObjectURL(blob)
                          const a: HTMLAnchorElement =
                            document.createElement('a')
                          a.href = fileURL
                          a.target = '_blank'
                          document.body.appendChild(a)
                          a.click()
                        }

                        return (
                          <HeaderButton
                            onClick={handlePrint2}
                            {...Theme.proformsDetailsStyles.headerButton}
                          >
                            <img src={PrintIcon} alt={'Print'} />
                            {locales.buttons.printWithoutData}
                          </HeaderButton>
                        )
                      }}
                    </BlobProvider>*/}
                  </ButtonCont>
                </HeaderInner>
                {/*<HeaderInnerRight>
                  <PrimaryButton
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(`actionButton`)
                    }
                    {...Theme.proformsDetailsStyles.headerButtonProps}
                    {...Theme.proformsDetailsStyles.primaryButton}
                  >
                    {'More actions'}
                  </PrimaryButton>
                </HeaderInnerRight>*/}
              </HeaderList>
            </HeaderCont>
            <Body className={'hide-print'}>
              <AddressBody>
                <DetailsTable
                  title={locales.headers.details}
                  columns={DetailsArray}
                  data={store.TableStore.detailsData}
                  showArrow={false}
                />
              </AddressBody>
              {/*<AddressBody>
                <DetailsTable
                  title={locales.headers.deliveryAddress}
                  columns={AddressArray}
                  data={store.TableStore.detailsData.customer || {}}
                  showArrow={true}
                />
              </AddressBody>*/}
            </Body>
            <ProductListTable view={'supplier'} />

            <PrintFooter>
              Printed by {store.UserStore.user} on {new Date().toJSON()}
            </PrintFooter>
          </>
        ) : (
          <LoadingContainer>
            <Wrap></Wrap>
          </LoadingContainer>
        )}
      </Container>
      <SlidingPanel
        contentId={`actionButton`}
        title={`More actions for order ${store.RouteStore.currentDataId}`}
        icon={FilterIcon}
      >
        <ActionButton />
      </SlidingPanel>
    </>
  )
})
export default ProformsDetails
