import { observer } from 'mobx-react'
import {
  ButtonCont,
  Entry,
  Footer,
  InnerCont,
  Label,
  PrimaryButton,
  Setting,
  SettingsCategory,
  StyledSelect,
} from './languales.styles'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import { translations as en } from '../../../static/languages/en'
import { toast } from 'react-toastify'
import Select from 'react-select'

export const LanguagesComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [editedLanguage, setEditedLanguage] = useState('')
  const [newLanguage, setNewLanguage] = useState('')

  const [translationObject] = useState<{
    [key: string]: {
      [key: string]: string
    }
  }>(en)

  const [editableTranslationObject, setEditableTranslationObject] = useState<{
    [key: string]: { [key: string]: string }
  }>({})

  const addNewLanguage = async () => {
    if (
      newLanguage &&
      !store.TranslationsState.avaliableLanguages.includes(newLanguage)
    ) {
      await store.TranslationsState.addNewLanguage(newLanguage)
      await toast('Language added!', { type: 'success' })
      await store.TranslationsState.setAvailableLanguages([
        ...store.TranslationsState.avaliableLanguages,
        newLanguage,
      ])
      setNewLanguage('') // clear the input after adding
    } else {
      toast('Invalid language or already exists!', { type: 'error' })
    }
  }

  const handleChange =
    (mainKey: string, key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setEditableTranslationObject({
        ...editableTranslationObject,
        [mainKey]: {
          ...editableTranslationObject[mainKey],
          [key]: e.target.value,
        },
      })
    }

  useEffect(() => {
    setEditableTranslationObject(
      localStorage.getItem(`translationObject-${editedLanguage}`)
        ? JSON.parse(localStorage.getItem('translationObject-en') || '{}')
        : en,
    )
  }, [editedLanguage])

  return (
    <SettingsCategory>
      <h1>Languages Settings</h1>
      {!editedLanguage.length ? (
        <InnerCont>
          {store.TranslationsState.avaliableLanguages.map(
            (language: string) => (
              <h2 key={language}>
                <Label>
                  {store.TranslationsState.getLanguageLabel(language)}:
                </Label>
                <ButtonCont>
                  <PrimaryButton
                    onClick={() => setEditedLanguage(language)}
                    {...Theme.buttons.primaryButton}
                  >
                    Edit
                  </PrimaryButton>
                </ButtonCont>
              </h2>
            ),
          )}
          <div style={{ marginTop: '20px' }}>
            <StyledSelect>
              <Select
                options={store.TranslationsState.allLanguages}
                onChange={(newValue) => {
                  if (newValue) {
                    setNewLanguage(newValue.value)
                  } else toast('Language not selected!', { type: 'error' })
                }}
              />
            </StyledSelect>
            <PrimaryButton
              onClick={addNewLanguage}
              {...Theme.buttons.primaryButton}
            >
              Add New Language
            </PrimaryButton>
          </div>
        </InnerCont>
      ) : (
        <InnerCont>
          {Object.keys(translationObject).map((key, index) => {
            return (
              <Setting key={index}>
                <h2>{key}</h2>
                {editableTranslationObject &&
                  editableTranslationObject[key] &&
                  Object.keys(editableTranslationObject[key]).map(
                    (key2, index2) => {
                      return (
                        <Entry key={index2}>
                          <Label>{key2}</Label>
                          <input
                            type='text'
                            value={editableTranslationObject[key][key2]}
                            onChange={handleChange(key, key2)}
                          />
                        </Entry>
                      )
                    },
                  )}
              </Setting>
            )
          })}
          <Footer>
            <PrimaryButton
              onClick={() =>
                store.TranslationsState.addNewLanguage(editedLanguage)
              }
              {...Theme.buttons.primaryButton}
            >
              Save
            </PrimaryButton>
            <PrimaryButton
              onClick={() => setEditedLanguage('')}
              {...Theme.buttons.primaryButton}
            >
              Cancel
            </PrimaryButton>
          </Footer>
        </InnerCont>
      )}
    </SettingsCategory>
  )
})
