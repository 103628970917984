import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'

export class ReturnsStore {
  rootStore
  data: any[] = []
  pagination: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  } = { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 }
  loaded = true

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchData = async (): Promise<void> => {
    const url = 'https://returnsdev.apihulk.com/returns'
    const response = await axios.get(url)
    if (response.status === 200) {
      this.data = response.data
    }
  }

  @action.bound clearData = () => {
    this.data = []
  }
}
