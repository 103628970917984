/* eslint-disable */
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import { hiddenColumns } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './AddSupplierProduct.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import AddProductSearchComponent from '../addProductSearch/AddProductSearch.component'
import { FilterLabel, Input } from '../dataInput/DataInput.styles'
import { validate } from '../../static/validate'
import { socket } from '../../socket/socket'
import { toJS } from 'mobx'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  fetchData: () => void
}

const AddSupplierProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [changedObject, setChangedObject] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const columns = ['quantity', 'productID']
  const initialState = [
    {
      header: 'quantity',
      value: '1',
      error: '',
      rules: ['positive'],
    },
    {
      header: 'productID',
      value: '',
      error: '',
      rules: ['required', 'length'],
    },
  ]
  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }
  const applySearch = (product: any) => {
    const tempForm = [...form]
    tempForm[1].value = product.id
    setForm([...tempForm])
  }
  const handleSave = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=1&from=0`
    const data = {
      filterGroups: {
        rules: [
          {
            field: 'id',
            operator: '=',
            valueSource: 'value',
            value: form[1].value,
          },
        ],
        combinator: 'and',
        not: false,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    if (response.data.count) {
      const product = response.data.products[0]
      const supplierId = props.row
        .getAllCells()
        .find((e: any) => e.column.id === 'id')
        ?.getValue()
      const postUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${supplierId}`
      const postData = {
        id: supplierId,
        products: {
          [product.code]: {
            ProductID: product.code,
            ProductCode: product.code,
            Name: product.name,
            SupplierName: product.producer,
            SupplierDeliveryCode: '',
            SupplierOrderId: '',
            OrderLink: '',
            Notes: '',
            ProducerName: product.producer,
            OrderedQuantity: form[0].value,
            SupplierDeliveryDate: '',
            DeliveredQuantity: '0',
            DeliveredDate: '',
            PurchasePrice: '0',
            Unit: '',
            LastEditAuthor: store.UserStore.user,
            CreatedBy: store.UserStore.user,
          },
        },
      }
      const formattedData: any = store.TableStore.formatRequestData(postData)

      await axios.post(postUrl, formattedData, config)
      await toast('Success!', { type: 'success' })
      await store.SlidingPanelState.setVisibility(false)
      await socket.emit('editOrder', {
        id: supplierId,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
      await setLoading(false)
      await setTimeout(async () => await store.TableStore.fetchData(), 3000)
    } else {
      toast('Response with this orderID does not exist!', { type: 'error' })
    }
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setChangedObject(changed)
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <AddProductSearchComponent applySearch={applySearch} />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {'Quantity'}
        </FilterLabel>
        <Input
          type={'text'}
          value={form[0].value}
          onChange={(e) => {
            const updatedForm = [...form]
            const error = validate(form[0].rules, e.target.value)
            updatedForm[0] = { ...form[0], value: e.target.value, error: error }

            setForm(updatedForm)
          }}
          activeStyle={true}
          {...Theme.editProductsStyles.styledInput}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => changedObject && !loading && handleSave()}
          active={changedObject && !loading}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default AddSupplierProduct
