import Table from '../../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'

import { PrimaryButton } from '../../editGeneric/EditGeneric.styles'
import styled from 'styled-components'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import EditTimeZonesComponent from '../../editTimeZones/EditTimeZones.component'
/* eslint-disable */

const TimeZonesButton = styled.div`
  position: absolute;
  z-index: 2;
  right: 30px;
  transform: translateY(-16px);
  cursor: pointer;
`
const TimeZonesTable = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
      mandatory: true,
    },
    offset: {
      canFilter: false,
      editable: false,
      canSort: false,
      mandatory: true,
    },
    abbr: {
      canFilter: false,
      editable: false,
      canSort: false,
      mandatory: true,
    },
  }

  useEffect(() => {
    store.StoreSettingsStore.fetchData('timezones')
    return () => store.StoreSettingsStore.clearData()
  }, [])

  return (
    <>
      <TimeZonesButton>
        <PrimaryButton
          onClick={() =>
            store.SlidingPanelState.setSelectedSlider('timezones-edit')
          }
          {...Theme.buttons.primaryButton}
          active={true}
        >
          {'EDIT TIMEZONES'}
        </PrimaryButton>
      </TimeZonesButton>
      <Table
        config={{
          tableName: 'timezones',
          data: store.StoreSettingsStore.data || [],
          fetchData: () => store.StoreSettingsStore.fetchData('timezones'),
          columnHeaders: columns,
          displayColumnVisibility: true,
          allowColumnResize: true,
          displaySearch: false,
          displaySelect: false,
          displayExpand: false,
          displayTooltip: false,
          displayEntry: false,
          displayPaginator: false,
          displayInfinitySwitch: false,
          allowSort: true,
          bank: false,
          actions: null,
          headline: {
            visible: true,
            title: 'List of Timezones',
            subTitle: 'List of Timezones',
          },
          breadcrumb: [],
          loaded: store.StoreSettingsStore.loaded,
          pagination: tableStore.pagination.filtersview,
          setPagination: tableStore.setPagination,
        }}
      ></Table>
      <SlidingPanel contentId={'timezones-edit'}>
        <EditTimeZonesComponent data={store.StoreSettingsStore.data} />
      </SlidingPanel>
    </>
  )
})
export default TimeZonesTable
