/* eslint-disable */
import { observer } from 'mobx-react'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { SelectInfo } from 'rc-menu/es/interface'
import { Tooltip } from '@mui/material'
import Fade from '@mui/material/Fade'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'

import '../../../styles/RcMenu.css'
import {
  HeaderOptionWrap,
  HeaderRightInner,
  HeaderRight,
  HeaderTitle,
  InnerHeader,
  Resize,
} from './TableHeaderOptions.styles'
import {
  IconArrowDown,
  IconArrowRight,
  IconArrowsSort,
  IconArrowUp,
  IconChevronDown,
  IconSortAscending,
  IconSortDescending,
  IconSquareChevronDown,
} from '@tabler/icons-react'
import FilterIcon from '../../../assets/icons/tableFilter.svg'
import { HexColorPicker, RgbaStringColorPicker } from 'react-colorful'
import {
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
} from '../table/Table.styles'
import { Input } from '../../settings/tags/edits/colorInput/ColorInput.styles'
import { PredefinedColors } from '../../settings/tags/edits/colorInput/ColorField/ColorField.component'

interface Props extends PropsWithChildren {
  header: any
  //setSortBy: (val: any) => void
  columnWidth: boolean
  change: boolean
  toggleChange: (val: boolean) => void
  index: number
  setColumnOrder: (updater: Array<string>) => void
  keys: any
  identifier: string
  width?: number
}

const TableHeaderOptions: FC<Props> = observer(
  ({
    header,
    //setSortBy,
    columnWidth,
    change,
    toggleChange,
    index,
    setColumnOrder,
    keys,
    identifier,
  }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [triggerChange, setTriggerChange] = useState<boolean>(false)
    const [changeListener, setChangeListener] = useState<boolean>(false)

    const hasParent = !!header.column.parent

    const onOpenChange = (openKeys: any) => {
      setOpenKeys(openKeys)
    }

    const handleMenuSelect = (selectInfo: SelectInfo) => {
      const keysList = [
        ...selectInfo.selectedKeys.filter((a: string) => !a.startsWith('5')),
      ]
      setSelectedKeys(keysList)
    }

    const onPanelClick = () => {
      if (header.id !== 'selection' && header.id !== '_options')
        store.SlidingPanelState.setSelectedSlider(
          `tableHeader-${index}-${identifier}`
        )
    }

    useEffect(() => {
      toggleChange(!change)
    }, [
      //header.column.columnDef.headerCustomOptions.settings.wrapText,
      //header.column.columnDef.headerCustomOptions.settings.color,
      triggerChange,
      //keys,
    ])

    return (
      <>
        <InnerHeader
          selected={
            store.SlidingPanelState.selectedSlider ===
            `tableHeader-${index}-${identifier}`
          }
          onClick={() => onPanelClick()}
          {...Theme.tableStyles.innerHeader}
        >
          {header.id !== 'selection' && header.id !== '_options' ? (
            <Tooltip
              title={
                header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )
              }
              placement='bottom'
              arrow
              TransitionComponent={Fade}
            >
              <HeaderTitle
                isResizing={store.TableStore.isResizing}
                selected={
                  store.SlidingPanelState.selectedSlider ===
                  `tableHeader-${index}-${identifier}`
                }
                {...Theme.tableStyles.tableCont}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </HeaderTitle>
            </Tooltip>
          ) : (
            <HeaderTitle
              isResizing={store.TableStore.isResizing}
              selected={
                store.SlidingPanelState.selectedSlider ===
                `tableHeader-${index}-${identifier}`
              }
              {...Theme.tableStyles.tableCont}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </HeaderTitle>
          )}
          {header.id !== 'selection' && header.id !== '_options' && (
            <HeaderRight>
              {header.column.columnDef.headerCustomOptions.canSort ? (
                <HeaderRightInner>
                  {store.TableStore.sort.column == header.id ? (
                    store.TableStore.sort.desc == 'desc' ? (
                      <IconArrowDown size='16px' />
                    ) : (
                      <IconArrowUp size='16px' />
                    )
                  ) : (
                    <IconArrowRight size='16px' />
                  )}
                </HeaderRightInner>
              ) : (
                <></>
              )}
              {columnWidth ? (
                <Resize
                  onDoubleClick={() => header.column.resetSize()}
                  onMouseDown={header.getResizeHandler()}
                  //onMouseMove={() => toggleChange(!change)}
                  onTouchStart={header.getResizeHandler()}
                  onMouseUp={() => {
                    store.TableStore.setIsResizing(false)
                  }}
                  {...Theme.tableStyles.resize}
                />
              ) : (
                <Resize />
              )}
            </HeaderRight>
          )}
        </InnerHeader>
        <SlidingPanel
          contentId={`tableHeader-${index}-${identifier}`}
          title={
            header &&
            header.id &&
            store &&
            store.RouteStore &&
            store.RouteStore.currentPage &&
            locales &&
            locales[`${store.RouteStore.currentPage}Columns`] &&
            locales[`${store.RouteStore.currentPage}Columns`].hasOwnProperty(
              header.id
            )
              ? locales[`${store.RouteStore.currentPage}Columns`][header.id]
              : header && header.id
          }
          icon={FilterIcon}
        >
          <HeaderOptionWrap
            {...Theme.tableHeaderOptionsStyles.headerOptionWrap}
          >
            <>
              <Menu
                mode='inline'
                onOpenChange={onOpenChange}
                openKeys={openKeys}
                style={{ margin: 0, width: '100%' }}
                expandIcon={<IconChevronDown size='18px' />}
                selectedKeys={selectedKeys}
                onSelect={(selectInfo: SelectInfo) =>
                  handleMenuSelect(selectInfo)
                }
              >
                {header.column.columnDef.headerCustomOptions.canSort ? (
                  <SubMenu key='1' title={'Sort'}>
                    <MenuItem
                      key='1-1'
                      onClick={() => {
                        //setSortBy([])
                        setChangeListener(true)
                        if (identifier === 'main') {
                          store.TableStore.setSort(undefined, undefined)
                        }
                      }}
                      itemIcon={<IconArrowsSort size='18px' />}
                    >
                      {'None'}
                      {/*{locales.header.none}*/}
                    </MenuItem>
                    <MenuItem
                      key='1-2'
                      onClick={() => {
                        setChangeListener(true)
                        if (identifier === 'main') {
                          store.TableStore.setSort(header.id, 'desc')
                          store.TableStore.fetchData()
                        }

                        //setSortBy([{ id: header.id, desc: true }])
                      }}
                      itemIcon={<IconSortDescending size='18px' />}
                    >
                      {/*{locales.header.descending}*/}
                      {'Descending'}
                    </MenuItem>
                    <MenuItem
                      key='1-3'
                      onClick={() => {
                        setChangeListener(true)
                        //setSortBy([{ id: header.id, desc: false }])
                        if (identifier === 'main') {
                          store.TableStore.setSort(header.id, 'asc')
                          store.TableStore.fetchData()
                        }
                      }}
                      itemIcon={<IconSortAscending size='18px' />}
                    >
                      {/*{locales.header.ascending}*/}
                      {'Ascending'}
                    </MenuItem>
                  </SubMenu>
                ) : (
                  <></>
                )}
                <SubMenu key='2' title={'Info'}>
                  <MenuItem key='2-1'>
                    {header &&
                    header.id &&
                    store &&
                    store.RouteStore &&
                    store.RouteStore.currentPage &&
                    locales &&
                    locales[`${store.RouteStore.currentPage}Details`] &&
                    locales[
                      `${store.RouteStore.currentPage}Details`
                    ].hasOwnProperty(header.id)
                      ? locales[`${store.RouteStore.currentPage}Details`][
                          header.id
                        ]
                      : header && header.id}
                    {header.id}
                  </MenuItem>
                </SubMenu>
                <SubMenu key='3' title={'Text'}>
                  <MenuItem
                    key='3-1'
                    onClick={() => {
                      setChangeListener(true)
                      header.column.columnDef.headerCustomOptions.settings.wrapText =
                        true
                      store.TableStore.changeClipColumn(header.id, 'wrap')
                      setTriggerChange(!triggerChange)
                    }}
                  >
                    {/*{locales.header.wrapText}*/}
                    {'Wrap text'}
                  </MenuItem>
                  <MenuItem
                    key='3-2'
                    onClick={() => {
                      setChangeListener(true)
                      header.column.columnDef.headerCustomOptions.settings.wrapText =
                        false
                      store.TableStore.changeClipColumn(header.id, 'clip')
                      setTriggerChange(!triggerChange)
                    }}
                  >
                    {/*{locales.header.clipText}*/}
                    {'Clip Text'}
                  </MenuItem>
                </SubMenu>
                {/*<SubMenu key='4' title='Group by'>
                  <MenuItem
                    key='4-1'
                    onClick={() => {
                      header.aggregate = 'count'
                      setChangeListener(true)
                    }}
                  >
                    {locales.header.values}
                  </MenuItem>
                  <MenuItem
                    key='4-2'
                    onClick={() => (header.aggregate = 'uniqueCount')}
                  >
                    {locales.header.uniqueValues}
                  </MenuItem>
                  <MenuItem key='4-3' onClick={() => {}}>
                    {locales.header.sum}
                  </MenuItem>
                  <MenuItem
                    key='4-4'
                    onClick={() => (header.aggregate = 'median')}
                  >
                    {locales.header.median}
                  </MenuItem>
                  <MenuItem
                    key='4-5'
                    onClick={() => (header.aggregate = 'average')}
                  >
                    {locales.header.average}
                  </MenuItem>
                </SubMenu>*/}

                <SubMenu key='5' title={'Move'}>
                  <MenuItem
                    key={'5-0'}
                    onClick={() => {
                      if (index !== 0) {
                        ;[keys[index], keys[index - 1]] = [
                          keys[index - 1],
                          keys[index],
                        ]
                        setColumnOrder(keys)
                        store.SlidingPanelState.setSelectedSlider(
                          `tableHeader-${index - 1}-${identifier}`
                        )
                      }
                      toggleChange(!change)
                    }}
                  >
                    {/*{locales.header.moveLeft}*/}
                    {'Move Left'}
                  </MenuItem>
                  <MenuItem
                    key={'5-1'}
                    onClick={() => {
                      if (index !== keys.length) {
                        ;[keys[index], keys[index + 1]] = [
                          keys[index + 1],
                          keys[index],
                        ]
                        setColumnOrder(keys)
                        store.SlidingPanelState.setSelectedSlider(
                          `tableHeader-${index + 1}-${identifier}`
                        )
                      }
                      toggleChange(!change)
                    }}
                  >
                    {/* {locales.header.moveRight}*/}
                    {'Move Right'}
                  </MenuItem>
                </SubMenu>

                <SubMenu key='6' title={'Styles'}>
                  <MenuItem
                    key={'6-1'}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Input
                      activeStyle={true}
                      type='text'
                      value={
                        header.column.columnDef.headerCustomOptions.settings
                          .color
                      }
                      onChange={(e) => {
                        setChangeListener(true)
                        header.column.columnDef.headerCustomOptions.settings.color =
                          e.target.value
                        toggleChange(!change)
                        store.ViewStore.setColor(header.id, e.target.value)
                        localStorage.setItem(
                          `color-${store.RouteStore.currentPage}-${header.id}`,
                          `${e.target.value}`
                        )
                      }}
                    />
                    <HexColorPicker
                      color={
                        header.column.columnDef.headerCustomOptions.settings
                          .color
                      }
                      onChange={(val) => {
                        setChangeListener(true)
                        header.column.columnDef.headerCustomOptions.settings.color =
                          val
                        toggleChange(!change)
                        store.ViewStore.setColor(header.id, val)
                        localStorage.setItem(
                          `color-${store.RouteStore.currentPage}-${header.id}`,
                          `${val}`
                        )
                      }}
                    />
                    <PredefinedColors
                      onSelectColor={(color) => {
                        setChangeListener(true)
                        header.column.columnDef.headerCustomOptions.settings.color =
                          color
                        toggleChange(!change)
                        store.ViewStore.setColor(header.id, color)
                        localStorage.setItem(
                          `color-${store.RouteStore.currentPage}-${header.id}`,
                          `${color}`
                        )
                      }}
                    />
                  </MenuItem>
                  <MenuItem key={'6-2'}>
                    <SwitchStyled {...Theme.tableStyles.switchStyled}>
                      <SwitchCont>
                        <SwitchInputText>{'Add border'}</SwitchInputText>
                        <SwitchInput
                          onColor={'#e4bf71'}
                          offColor={'#e4bf71'}
                          checked={
                            header.column.columnDef.headerCustomOptions.settings
                              .border
                          }
                          onChange={(val) => {
                            header.column.columnDef.headerCustomOptions.settings.border =
                              val
                            setChangeListener(true)
                            toggleChange(!change)
                            store.ViewStore.setBorder(header.id, val)
                            localStorage.setItem(
                              `border-${store.RouteStore.currentPage}-${header.id}`,
                              val ? 'true' : 'false'
                            )
                          }}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={42}
                          handleDiameter={16}
                        />{' '}
                      </SwitchCont>
                    </SwitchStyled>
                  </MenuItem>
                </SubMenu>
                {changeListener ? (
                  <MenuItem
                    key='formatcolumn'
                    onClick={() => {
                      setChangeListener(false)
                      header.column.columnDef.headerCustomOptions.sort.desc =
                        true
                      header.column.columnDef.headerCustomOptions.settings.wrapText =
                        true
                      header.column.columnDef.headerCustomOptions.settings.color =
                        'transparent'
                      header.column.columnDef.headerCustomOptions.settings.border =
                        false
                    }}
                  >
                    {/*{locales.header.backToDefault}*/}
                    {'Back to default'}
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Menu>
            </>
          </HeaderOptionWrap>
        </SlidingPanel>
      </>
    )
  }
)
export default TableHeaderOptions
