import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../App'
import { useParams } from 'react-router-dom'
import { ReactComponent as ArrowsRight } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as ArrowsLeft } from '../../assets/icons/arrow-left.svg'
import {
  Container,
  OuterCont,
  SettingsList,
  SettingsOpenButton,
  StyledLink,
} from './settings.styles'
import { IconChevronDown } from '@tabler/icons-react'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'
import { InterfaceComponent } from '../../components/settings/view/interface/Interface.component'
import { LanguagesComponent } from '../../components/settings/language/languages.component'
import { MyUserProfileComponent } from '../../components/settings/user/myUserProfile/MyUserProfile.component'
import AuditTable from '../../components/auditTable/AuditTable.component'
import { TagsComponent } from '../../components/settings/tags/Tags.component'
import { ThemeSetterComponent } from '../../components/settings/view/themeSetter/ThemeSetter.component'
import { AdminPortal } from '@frontegg/react'
import { FeatureDisable } from '../../components/FeatureDisable/FeatureDisable.component'
import PredefinedFiltersTableComponent from '../../components/settings/filters/PredefinedFiltersTable.component'
import { CollectionTagsTableComponent } from '../../components/settings/tags/collectionTagsTable/CollectionTagsTable.component'
import StatusesTable from '../../components/settings/statuses/StatusesTable.component'
import AvailabilityStatusComponent from '../../components/settings/statuses/AvailabilityStatus.component'
import ShippingTimeStatusComponent from '../../components/settings/statuses/ShippingTimeStatus.component'
import SalesDataComponent from '../../components/settings/statuses/SalesData.component'
import CurrenciesComponent from '../../components/settings/storeSettings/Currencies.component'
import TimeZonesTable from '../../components/settings/storeSettings/TimeZones.component'
import StoreSettingsComponent from '../../components/settings/storeSettings/StoreSettings.component'
import CategoriesTable from '../../components/settings/categories/CategoriesTable.component'
import NumerationComponent from '../../components/settings/orders/Numeration.component'
import TagTreeGenericComponent from '../../components/settings/TagTreeGeneric.component'
import CategoriesTreeGeneric from '../../components/settings/CategoriesTreeGeneric.component'
import ReturnPolicyComponent from '../../components/settings/returns/ReturnPolicy.component'
import ReturnsTableComponent from '../../components/settings/returns/ReturnsTable.component'

const Settings = observer(() => {
  const store = useContext(StoreContext)
  const { Theme, Navigation, themeName } = store.Theme
  const params = useParams()
  const [openMenu, setOpenMenu] = useState(true)

  const getComponentFromUrl = () => {
    switch (params.page) {
      case 'interface':
        return <InterfaceComponent />
      case 'themesetter':
        if (store.UserStore.features.includes('themesmgm'))
          return <ThemeSetterComponent />
        else return <FeatureDisable />
      case 'languages':
        return <LanguagesComponent />
      case 'myuserprofile':
        return <MyUserProfileComponent />
      case 'auditlog':
        return <AuditTable />
      case 'filtersview':
        return <PredefinedFiltersTableComponent />
      case 'tags':
      case 'tagsgroup':
      case 'tagsroot':
        if (store.UserStore.features.includes('tagmgm'))
          return <TagsComponent />
        else return <FeatureDisable />
      case 'collections':
        if (store.UserStore.features.includes('tagmgm'))
          return <CollectionTagsTableComponent />
        else return <FeatureDisable />
      case 'tagstree':
        if (store.UserStore.features.includes('tagmgm'))
          return <TagTreeGenericComponent />
        else return <FeatureDisable />
      case 'categoriestree':
        return <CategoriesTreeGeneric />
      case 'statuses':
        return <StatusesTable />
      case 'availability':
        return <AvailabilityStatusComponent />
      case 'shippingtime':
        return <ShippingTimeStatusComponent />
      case 'saleschannels':
        return <SalesDataComponent />
      case 'currencies':
        return <CurrenciesComponent />
      case 'timezones':
        return <TimeZonesTable />
      case 'storesettings':
        return <StoreSettingsComponent />
      case 'numeration':
        return <NumerationComponent />
      case 'returnpolicy':
        return <ReturnPolicyComponent />
      case 'returntable':
        return <ReturnsTableComponent />
      case 'categories':
        return <CategoriesTable />
      default:
        return <>{params.page}</>
    }
  }
  const showAdminProfile = () => {
    AdminPortal.show()
  }

  useEffect(() => {
    if (params.page?.length) setOpenMenu(false)
  }, [params.page])

  return (
    <OuterCont>
      <SettingsList active={openMenu}>
        <Menu
          mode='inline'
          style={{ margin: 0, width: '100%' }}
          expandIcon={<IconChevronDown size='18px' />}
        >
          <SubMenu key='User' title='Account'>
            <MenuItem key='myaccount'>
              <StyledLink to={'/admin/user/myaccount'}>My Account</StyledLink>
            </MenuItem>
            <MenuItem
              key='myuserprofile'
              style={{ cursor: 'pointer' }}
              onClick={showAdminProfile}
            >
              My User Profile
            </MenuItem>
          </SubMenu>
          <SubMenu key='Settings' title='Settings'>
            <SubMenu key='General' title='General'>
              <MenuItem key='companyinfo'>
                <StyledLink to={'/admin/general/companyinfo'}>
                  Company Information
                </StyledLink>
              </MenuItem>
              <MenuItem key='companydata'>
                <StyledLink to={'/admin/general/companydata'}>
                  Company Data
                </StyledLink>
              </MenuItem>
              <MenuItem key='admins'>
                <StyledLink to={'/admin/general/admins'}>
                  Administrators
                </StyledLink>
              </MenuItem>
              <MenuItem key='push'>
                <StyledLink to={'/admin/general/push'}>
                  Web Push Notifications
                </StyledLink>
              </MenuItem>
              <MenuItem key='domains'>
                <StyledLink to={'/admin/general/domains'}>Domains</StyledLink>
              </MenuItem>
              <MenuItem key='email'>
                <StyledLink to={'/admin/general/email'}>
                  Email address
                </StyledLink>
              </MenuItem>
              <MenuItem key='post'>
                <StyledLink to={'/admin/general/post'}>
                  Post Accounts
                </StyledLink>
              </MenuItem>
              <MenuItem key='cache'>
                <StyledLink to={'/admin/general/cache'}>
                  Cache Memory
                </StyledLink>
              </MenuItem>
              <MenuItem key='storesettings'>
                <StyledLink to={'/admin/general/storesettings'}>
                  Store Settings
                </StyledLink>
              </MenuItem>
            </SubMenu>
            <SubMenu key={'Statuses'} title={'Statuses'}>
              <MenuItem key='availability'>
                <StyledLink to={'/admin/general/availability'}>
                  Availability
                </StyledLink>
              </MenuItem>
              <MenuItem key='statuses'>
                <StyledLink to={'/admin/general/statuses'}>
                  Availability by Producer
                </StyledLink>
              </MenuItem>
              <MenuItem key='shippingtime'>
                <StyledLink to={'/admin/general/shippingtime'}>
                  Shipping Time Status
                </StyledLink>
              </MenuItem>
            </SubMenu>
            <SubMenu key={'tags'} title={'Tags'}>
              <MenuItem
                onClick={() => {
                  store.TagsStore.setView('group')
                }}
                key='tagsgroup'
              >
                <StyledLink to={'/admin/general/tagsgroup'}>
                  Group Management
                </StyledLink>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  store.TagsStore.setView('root')
                }}
                key='tagsroot'
              >
                <StyledLink to={'/admin/general/tagsroot'}>
                  Root Management
                </StyledLink>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  store.TagsStore.setView('tag')
                }}
                key='tags-2'
              >
                <StyledLink to={'/admin/general/tags'}>
                  Tags Management
                </StyledLink>
              </MenuItem>
              <MenuItem key='collections'>
                <StyledLink to={'/admin/general/collections'}>
                  Collections Management
                </StyledLink>
              </MenuItem>
              <MenuItem key='tagstree'>
                <StyledLink to={'/admin/general/tagstree'}>
                  Tags Tree View
                </StyledLink>
              </MenuItem>
            </SubMenu>
            <SubMenu key='Regional' title='Regional'>
              <MenuItem key='currencies'>
                <StyledLink to={'/admin/regional/currencies'}>
                  Currencies
                </StyledLink>
              </MenuItem>
              <MenuItem key='timezones'>
                <StyledLink to={'/admin/regional/timezones'}>
                  Timezones
                </StyledLink>
              </MenuItem>
              <MenuItem key='languages'>
                <StyledLink to={'/admin/view/languages'}>Languages</StyledLink>
              </MenuItem>
            </SubMenu>
          </SubMenu>
          <SubMenu key='Products' title='Products'>
            <SubMenu key={'Categories'} title={'Categories'}>
              <MenuItem key='categories'>
                <StyledLink to={'/admin/general/categories'}>
                  Categories Management
                </StyledLink>
              </MenuItem>
              <MenuItem key='categoriestree'>
                <StyledLink to={'/admin/general/categoriestree'}>
                  Categories Tree View
                </StyledLink>
              </MenuItem>
            </SubMenu>
          </SubMenu>
          <SubMenu key='Orders' title='Orders'>
            <MenuItem key='numeration'>
              <StyledLink to={'/admin/orders/numeration'}>
                Order Numeration options
              </StyledLink>
            </MenuItem>
            <MenuItem key='returnpolicy'>
              <StyledLink to={'/admin/orders/returnpolicy'}>
                Returns Policy
              </StyledLink>
            </MenuItem>
            <MenuItem key='returntable'>
              <StyledLink to={'/admin/orders/returntable'}>
                Returns Table (mock)
              </StyledLink>
            </MenuItem>
          </SubMenu>
          <SubMenu key={'Content'} title={'Layout & Content'}>
            <SubMenu key='View' title='View'>
              <MenuItem key='interface'>
                <StyledLink to={'/admin/view/interface'}>
                  Interface options
                </StyledLink>
              </MenuItem>
              <MenuItem key='themesetter'>
                <StyledLink to={'/admin/view/themesetter'}>
                  Theme Options
                </StyledLink>
              </MenuItem>
              <MenuItem key='filtersview'>
                <StyledLink to={'/admin/view/filtersview'}>
                  Predefined Filters View
                </StyledLink>
              </MenuItem>
              <MenuItem key='objectsview'>
                <StyledLink to={'/admin/view/objectsview'}>
                  Predefined Objects View
                </StyledLink>
              </MenuItem>
            </SubMenu>
          </SubMenu>
          <SubMenu key={'Audit'} title={'Security'}>
            <MenuItem key='auditlog'>
              <StyledLink to={'/admin/view/auditlog'}>Audit Log</StyledLink>
            </MenuItem>
          </SubMenu>
          <SubMenu key={'Integrations'} title={'Integrations'}>
            <MenuItem key='Sales Channels'>
              <StyledLink to={'/admin/view/saleschannels'}>
                Sales Channels
              </StyledLink>
            </MenuItem>
          </SubMenu>
        </Menu>
      </SettingsList>
      <SettingsOpenButton
        onClick={() => setOpenMenu(!openMenu)}
        active={openMenu}
      >
        {openMenu ? <ArrowsLeft /> : <ArrowsRight />}
      </SettingsOpenButton>
      <Container active={openMenu} {...Theme.SettingsPage.container}>
        {getComponentFromUrl()}
      </Container>
    </OuterCont>
  )
})
export default Settings
